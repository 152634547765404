<template>
  <panel-main-template>
    <template v-slot:page-title>
      Поддержка
    </template>
    <template v-slot:page-content>
      <div class="support--container">
        <div class="support--actions">
          <div @click="openMassMPopup" class="btn btn-sm btn-info">Массовая рассылка</div>
          <div @click="updateList" class="btn btn-sm btn-info">Обновить</div>
        </div>
        <div v-if="attachList.length > 0" class="support-attach shadow-card mrg-t-25">
          <div class="text-bold">Закрепленные чаты</div>
          <div class="support-list mrg-t-15">
            <div class="table--container">
              <div class="table--header">
                <div class="id">ID юзера</div>
                <div class="email">E-mail</div>
                <div class="message">Описание</div>
                <div class="date">Дата открытия</div>
                <div class="actions">Действия</div>
              </div>
              <div class="table--rows">
                <div v-for="item in attachList" :key="item.id" class="table--row">
                  <div @click="openChat(item.user_id, item.user.email)" class="id">{{item.id}}</div>
                  <div @click="openChat(item.user_id, item.user.email)" class="email">{{item.user.email}}</div>
                  <div @click="openChat(item.user_id, item.user.email)" class="message" v-html="item.description"></div>
                  <div @click="openChat(item.user_id, item.user.email)" class="date">{{item.open_dt}}</div>
                  <div>
                    <div @dblclick="closeAttach(item.id)" class="btn btn-sm btn-danger">
                      <f-awesome icon="times"></f-awesome>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="newData.length > 0" class="support-new shadow-card pulse mrg-t-25">
          <div class="text-bold">Список непрочитанных</div>
          <div class="support-list mrg-t-15">
            <div class="table--container">
              <div class="table--header">
                <div class="id">ID</div>
                <div class="email">E-mail</div>
                <div class="message">Сообщение</div>
                <div class="date">Дата</div>
              </div>
              <div class="table--rows">
                <div @click="openChat(item.user_id, item.user.email)" v-for="item in newData" :key="item.id" class="table--row">
                  <div class="id">{{item.id}}</div>
                  <div class="email">{{item.user.email}}</div>
                  <div class="message" v-html="item.message"></div>
                  <div class="date">{{item.date}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="support--list shadow-card mrg-t-25">
          <div class="text-bold">Список последних</div>
          <div class="support-list mrg-t-15">
            <div class="table--container">
              <div class="table--header">
                <div class="id">ID</div>
                <div class="email">E-mail</div>
                <div class="message">Сообщение</div>
                <div class="date">Дата</div>
              </div>
              <div class="table--rows">
                <div @click="openChat(item.user_id, item.user.email)" v-for="item in data" :key="item.id" class="table--row">
                  <div class="id">{{item.id}}</div>
                  <div class="email">
                    <span v-if="item.user">{{item.user.email}}</span>
                  </div>
                  <div class="message" v-html="item.message"></div>
                  <div class="date">{{item.date}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="pagginator mrg-t-20">
          <paginator
              :pages="pagesCountPaginator"
              :now="thisPage"
              @setPage="setPage"
          ></paginator>
        </div>


        <popup
            :closeButton="chatPopup.closeButton"
            :show="chatPopup.show"
            :size="'lg'"
            @closePopup="closeChatPopup"
        >
          <div slot="header">Карточка {{clickUserEmail}}</div>
          <div slot="body">
            <ul class="tabs-container">
              <li
                  @click="clickTab(item)"
                  v-bind:class="{ active: item.isActive }"
                  v-for="item in tabs"
                  :key="item.id"
              >
                {{ item.name }}
              </li>
            </ul>
            <div class="content">
              <user-info v-if="activeTabIndex === 1" :id="clickUserId"></user-info>
              <user-companies v-if="activeTabIndex === 2" :id="clickUserId"></user-companies>
              <user-config v-if="activeTabIndex === 3" :id="clickUserId"></user-config>
              <user-statistics v-if="activeTabIndex === 6" :id="clickUserId"></user-statistics>
              <user-actions v-if="activeTabIndex === 7" :id="clickUserId"></user-actions>
              <user-support 
                v-if="activeTabIndex === 8" 
                :id="clickUserId"
                @update="updateAll"
              ></user-support>
              <user-balance v-if="activeTabIndex === 9" :id="clickUserId"></user-balance>
              <user-contract v-if="activeTabIndex === 10" :id="clickUserId"></user-contract>
              <user-acts v-if="activeTabIndex === 11" :id="clickUserId"></user-acts>
              <user-invoices v-if="activeTabIndex === 12" :id="clickUserId"></user-invoices>
            </div>
          </div>
        </popup>


        <popup
            :closeButton="massMessagePopup.closeButton"
            :show="massMessagePopup.show"
            @closePopup="closeMassMPopup"
        >
          <div slot="header">Массовая рассылка</div>
          <div slot="body">
            <div class="input-block">
              <label>Текст рассылки</label>
              <wysiwyg rows="6" v-model="massMessageModel" placeholder="Введите текст..."></wysiwyg>
            </div>
            <div class="input-block">
              <div @dblclick="setMassMessage('all')" class="btn btn-sm btn-success mrg-t-10">Всем</div>
              <div @dblclick="setMassMessage('active')" class="btn btn-sm btn-success mrg-t-10">Активным</div>
              <div @dblclick="setMassEmail('self')" class="btn btn-sm btn-success mrg-t-10">E-mail себе</div>
              <div @dblclick="setMassEmail('active')" class="btn btn-sm btn-success mrg-t-10">E-mail активным</div>
            </div>
          </div>
        </popup>


      </div>
    </template>
  </panel-main-template>
</template>

<script>
// @ is an alias to /src

import PanelMainTemplate from "../../components/panel/MainTemplate";
import axios from "axios";
import Paginator from "@/components/Paginator";
import Popup from "@/components/Popup";
import UserSupport from "@/components/panel/users/UserSupport";
import UserInfo from "@/components/panel/users/UserInfo";
import UserCompanies from "@/components/panel/users/UserCompanies";
import UserStatistics from "@/components/panel/users/UserStatistics";
import UserActions from "@/components/panel/users/UserActions";
import UserConfig from "@/components/panel/users/UserConfig";
import UserBalance from "../../components/panel/users/UserBalance";
import UserContract from "../../components/panel/users/UserContract";
import UserActs from "../../components/panel/users/UserActs";
import UserInvoices from "../../components/panel/users/UserInvoices";

// const permissions = new Permissions();

export default {
  name: "Support",
  components: {
    UserSupport,
    Popup,
    Paginator,
    PanelMainTemplate,
    UserInfo,
    UserCompanies,
    UserConfig,
    UserBalance,
    UserContract,
    UserActs,
    UserInvoices,
    UserStatistics,
    UserActions
  },
  data() {
    return {
      tabs: [
        {
          id: 1,
          name: "Общая информация",
          isActive: false
        },
        {
          id: 2,
          name: "Компании",
          isActive: false
        },
        {
          id: 3,
          name: "Конфиги",
          isActive: false
        },
        {
          id: 6,
          name: "Статистика",
          isActive: false
        },
        {
          id: 7,
          name: "Доп.действия",
          isActive: false
        },
        {
          id: 8,
          name: "Чат",
          isActive: false
        },
        {
          id: 9,
          name: "Баланс/История",
          isActive: false
        },
        {
          id: 10,
          name: "Contract",
          isActive: false
        },
        {
          id: 11,
          name: "Акты",
          isActive: false
        },
        {
          id: 12,
          name: "Invoices",
          isActive: false
        }
      ],
      massMessageModel: '',
      massMessagePopup: {
        show: false,
        closeButton: "Закрыть",
      },
      chatPopup: {
        show: false,
        closeButton: "Закрыть",
      },
      clickUserId: '',
      clickUserEmail: '',
      data: [],
      newData: [],
      attachList: [],
      thisPage: 1, // Текущая страница
      pagesCountPaginator: 0, // Кол-во страниц
      pageSize: 25, // Сколько записей подгружать на 1 страницу
    };
  },
  computed: {
    activeTabIndex() {
      let result = 0;
      this.tabs.forEach(function(item) {
        if (item.isActive) {
          result = item.id;
        }
      });
      return result;
    }
  },
  methods: {
    updateList(){
      this.getData();
    },
    closeAttach(id){
      this.$store.dispatch("startPreloader");
      let formData = new FormData();
      formData.append("id", id);
      axios
          .post(`/support/close-attach`, formData)
          .then(() => {
            this.getAttach();
            this.$store.dispatch("stopPreloader");
          })
          .catch(err => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
            this.$store.dispatch("stopPreloader");
          });
    },
    clickTab(item) {
      for (let key in this.tabs) {
        if (this.tabs[key].id === item.id) {
          this.tabs[key].isActive = true;
        } else {
          this.tabs[key].isActive = false;
        }
      }
    },
    openMassMPopup(){
      this.massMessagePopup.show = true;
    },
    closeMassMPopup(){
      this.massMessagePopup.show = false;
    },
    setMassMessage(type){
      this.$store.dispatch("startPreloader");
      let formData = new FormData();
      formData.append("message", this.massMessageModel);
      formData.append("type", type);
      axios
          .post(`/support/mass-message`, formData)
          .then(resp => {
            if(resp.data.result == 'ok'){
              this.closeMassMPopup();
              this.$store.dispatch("addNotification", {
                text: 'Успешно отправлено (' +resp.data.usersCount +' юзеров)',
                time: 4,
                color: "success"
              });
              this.getData();
            }
            this.$store.dispatch("stopPreloader");
          })
          .catch(err => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
            this.$store.dispatch("stopPreloader");
          });
    },
    setMassEmail(type){
      this.$store.dispatch("startPreloader");
      let formData = new FormData();
      formData.append("message", this.massMessageModel);
      formData.append("type", type);
      axios
          .post(`/support/mass-email`, formData)
          .then(resp => {
            if(resp.data.result == 'ok'){
              this.closeMassMPopup();
              this.$store.dispatch("addNotification", {
                text: 'Создана очередь для отправки ' +resp.data.usersCount +' e-mail',
                time: 4,
                color: "success"
              });
            }
            this.$store.dispatch("stopPreloader");
          })
          .catch(err => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
            this.$store.dispatch("stopPreloader");
          });
    },
    openChat(userId, email){
      this.clickUserId = +userId;
      this.clickUserEmail = email;
      this.chatPopup.show = true;
    },
    closeChatPopup(){
      this.chatPopup.show = false;
    },
    updateAll(){
      this.getData();
      this.getAttach();
    },
    getData() {
      this.$store.dispatch("startPreloader");
      let formData = new FormData();
      formData.append("page", this.thisPage);
      formData.append("page_size", this.pageSize);
      axios
          .post(`/support/get-list`, formData)
          .then(resp => {
            this.data = resp.data.all_messages.data;
            this.pagesCountPaginator = resp.data.all_messages.pages;
            this.newData = resp.data.new;
            this.$store.dispatch("stopPreloader");
          })
          .catch(err => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
            this.$store.dispatch("stopPreloader");
          });
    },
    getAttach() {
      axios
          .post(`/support/get-attach`)
          .then(resp => {
            this.attachList = resp.data;
          })
          .catch(err => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
            this.$store.dispatch("stopPreloader");
          });
    },
    setPage(val) {
      this.thisPage = val;
      this.getData();
    },
  },
  created() {
    this.getData();
    this.getAttach();
  }
};
</script>

<style scoped lang="less">
.pulse{
  animation: pulse 1s infinite;
}
</style>
