<template>
  <div class="user-permissions--container">
    <simple-select
        :data="allPerms"
        :default="defaultPerms"
        @changeValue="changePermsSelect"
    ></simple-select>
    <div class="input-block mrg-t-10">
      <div @click="savePerms" class="btn btn-sm btn-success">Сохранить</div>
    </div>
  </div>
</template>

<script>
// import axios from "axios";

import axios from "axios";
import SimpleSelect from "@/components/SimpleSelect";

export default {
  name: "UserPermissions",
  components: {SimpleSelect},
  data() {
    return {
      allPerms: [],
      userPerms: [],
      selectedPerms: []
    };
  },
  props: {
    id: Number
  },
  watch: {
    id(){
      this.getPerms();
    }
  },
  computed: {
    defaultPerms(){
      let result = [];
      if(this.userPerms.length){
        for(let key in this.userPerms){
          result[result.length] = this.userPerms[key].id;
        }
      }
      return result;
    }
  },
  methods: {
    savePerms(){
      this.$store.dispatch("startPreloader");
      let formData = new FormData();
      formData.append("user_id", this.id);
      formData.append("perms", JSON.stringify(this.selectedPerms));
      axios
          .post(`/users/set-permissions`, formData)
          .then(resp => {
            if(resp.data.result == 'ok'){
              this.$store.dispatch("addNotification", {
                text: 'Успешно',
                time: 3,
                color: "success"
              });
            }
            this.$store.dispatch("stopPreloader");
          })
          .catch(err => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
            this.$store.dispatch("stopPreloader");
          });
    },
    changePermsSelect(val) {
      this.selectedPerms = val;
    },
    getPerms(){
      this.$store.dispatch("startPreloader");
      let formData = new FormData();
      formData.append("user_id", this.id);
      axios
          .post(`/users/get-permissions`, formData)
          .then(resp => {
            this.allPerms = this.preparePermData(resp.data.all_perms);
            this.userPerms = this.preparePermData(resp.data.user_perms);
            this.$store.dispatch("stopPreloader");
          })
          .catch(err => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
            this.$store.dispatch("stopPreloader");
          });
    },
    preparePermData(val) {
      let result = [];
      for (let key in val) {
        let obj = {};
        obj.id = val[key].name;
        obj.name = val[key].description;
        result.push(obj);
      }
      return result;
    },
  },
  created() {
    this.getPerms();
  }
};
</script>

<style scoped lang="less">

</style>
