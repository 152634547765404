<template>
  <footer>
    <preloader></preloader>
    <set-manifest></set-manifest>

    <div class="container"></div>
  </footer>
</template>

<script>
import Preloader from "./Preloader";
import SetManifest from "./SetManifest.vue";

export default {
  name: "MainFooter",
  components: {
    Preloader,
    SetManifest
},
  data() {
    return {
      slidesData: []
    };
  },
  methods: {}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
header {
  color: white;
  height: 60px;
  background-color: #3c4959;
}
.home--header-container {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}
.home--logo {
  font-size: 20px;
  font-weight: 700;
  line-height: 60px;
  color: white;

  a {
    color: white;
    text-decoration: none;

    img {
      width: 50px;
      vertical-align: middle;
    }
  }
}

</style>
