import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from "@/views/Login";
import Home from "@/views/Home";
import Server from "@/views/panel/Server";
import Roles from "@/views/panel/Roles";
import Dicts from "@/views/panel/Dicts";
import Users from "@/views/panel/Users";
import Support from "@/views/panel/Support";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/login/',
    name: 'Login',
    component: Login
  },
  {
    path: '/server/',
    name: 'Server',
    component: Server
  },
  {
    path: '/roles/',
    name: 'Roles',
    component: Roles
  },
  {
    path: '/dicts/',
    name: 'Dicts',
    component: Dicts
  },
  {
    path: '/users/',
    name: 'Users',
    component: Users
  },
  {
    path: '/support/',
    name: 'Support',
    component: Support
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
