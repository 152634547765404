<template>
  <div class="s-select--container">
    <ul class="s-select--ul">
      <li v-for="(li, index) in selects" :key="index" class="s-select--li">
        <div>
          <select
            @input="onInput(index, $event.target.value)"
            v-bind:disabled="li.disabled == 1"
            v-model="li.model"
            class="s-select--select"
          >
            <option
              :value="option.id"
              v-for="option in li.data"
              :key="option.id"
              >{{ option.name }}</option
            >
          </select>
        </div>
        <div class="actions">
          <div
            v-if="index === 0"
            class="btn-circle btn-success"
            @click="addSelect"
          >
            <f-awesome icon="plus"></f-awesome>
          </div>
          <div
            v-if="index !== 0"
            class="btn-circle btn-danger"
            @click="deleteSelect(index)"
          >
            <f-awesome icon="times"></f-awesome>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "SimpleSelect",
  data() {
    return {
      selects: [
        {
          model: "",
          disabled: 0,
          data: []
        }
      ]
    };
  },
  props: {
    data: Array, // Все данные доступные для выбора (id, name)
    reset: Boolean, // При передаче true сбрасывает компонент
    default: Array // Устанавливает выбранные значения из списка data. На вход [id, id...]
  },
  computed: {},
  watch: {
    data: function(val) {
      this.selects[0].data = val;
    },
    reset: function(val) {
      if (val === true) {
        this.selects = [];
        this.selects[0] = {};
        this.selects[0].model = "";
        this.selects[0].disabled = 0;
        this.selects[0].data = this.data;
      }
      this.$emit("resetFalse");
    },
    default: function(val) {
      if(val.length){
        this.selects = [];

        for (let key in val) {
          let newSelect = {};
          if (this.selects.length + 1 >= val.length) {
            newSelect.disabled = 0;
          } else {
            newSelect.disabled = 1;
          }
          newSelect.data = this.returnFreeValues();
          newSelect.model = val[key];
          this.selects.push(newSelect);
        }
        this.initChangeValue();
      }
    }
  },
  methods: {
    addSelect() {
      if (this.selects[this.selects.length - 1].model === "") {
        this.$store.dispatch("addNotification", {
          text:
            "Невозможно добавить новое поле, не выбрав значение у предыдущего",
          time: 6,
          color: "danger"
        });
        return false;
      }
      if (this.selects.length >= this.data.length) {
        this.$store.dispatch("addNotification", {
          text: "Невозможно добавить элементов больше, чем возможно выбрать",
          time: 6,
          color: "danger"
        });
        return false;
      }

      // При добавлении нового элемента, сделаем предыдущий недоступным для изменения
      this.selects[this.selects.length - 1].disabled = 1;

      let newSelect = {};
      newSelect.model = "";
      newSelect.disabled = 0;
      newSelect.data = this.returnFreeValues();

      this.selects.push(newSelect);
    },
    deleteSelect(index) {
      this.selects.splice(index, 1);
      // При удалении элемента, сделаем последний доступным для изменения
      this.selects[this.selects.length - 1].disabled = 0;
      this.selects[this.selects.length - 1].data = this.returnFreeValues();
      this.$emit("changeValue", this.actualSelects());
    },
    onInput(index, value) {
      let result = [];
      for (let key in this.selects) {
        if (this.selects[key].model !== "" && +key !== +index) {
          result[result.length] = this.selects[key].model;
        } else if (+key === +index) {
          result[result.length] = value;
        }
      }
      this.$emit("changeValue", result);
    },
    initChangeValue() {
      let result = [];
      for (let key in this.selects) {
        if (this.selects[key].model !== "") {
          result[result.length] = this.selects[key].model;
        }
      }
      this.$emit("changeValue", result);
    },
    returnFreeValues() {
      let allData = this.data.slice();

      for (let select in this.selects) {
        for (let key in allData) {
          if (
            this.selects[select].model === allData[key].id &&
            +this.selects[select].disabled == 1
          ) {
            allData.splice(key, 1);
          }
        }
      }
      return allData;
    },
    actualSelects() {
      let result = [];
      for (let key in this.selects) {
        if (this.selects[key].model !== "") {
          result[result.length] = this.selects[key].model;
        }
      }
      return result;
    }
  },
  created() {}
};
</script>

<style scoped lang="less">
.s-select--ul {
  margin: 0;
  padding: 0;
}
.s-select--li {
  list-style-type: none;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  flex-direction: row;

  .actions{
    position: relative;

    .btn-circle{
      position: absolute;
      top: 0;
      bottom: 0;
      left: 3px;
      margin: auto;

    }
  }

  & > div:nth-of-type(1) {
    width: 80%;
  }
  & > div:nth-of-type(2) {
    width: 20%;
    /*text-align: center;*/
  }
}
.s-select--select {
  width: 100%;
  border-radius: 5px;
  box-sizing: border-box;
  border: 1px solid #ced4da;
  padding: 5px 8px;
  background-clip: padding-box;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  font-size: 15px;
  color: #495057;
}
.s-select--select:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
</style>
