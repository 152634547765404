<template>
  <header>
    <div class="container">
      <div class="home--header-container">
        <div class="home--logo">
          <a href="/"
            ><img src="../assets/img/header-logo.png" /> Enroller</a
          >
        </div>
        <div v-if="userData" class="home--account-card">
          {{ userData[0].email }}
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "MainHeader",
  components: {},
  computed: {
    ...mapState({
      userData: state => state.authorization.userData
    })
  },
  data() {
    return {
      slidesData: []
    };
  },
  methods: {}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
header {
  color: white;
  height: 60px;
  background-color: #3c4959;
}
.home--account-card {
  line-height: 60px;
}
.home--header-container {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}
.home--logo {
  font-size: 20px;
  font-weight: 700;
  line-height: 54px;
  color: white;

  a {
    color: white;
    text-decoration: none;

    img {
      width: 40px;
      vertical-align: middle;
    }
  }
}
</style>
