<template>
  <panel-main-template>
    <template v-slot:page-title>
      Роли и права
    </template>
    <template v-slot:page-content>
      <div class="roles--container">
        <div class="roles">
          <div class="input-block">
            <label>Название роли</label>
            <input type="text" v-model="role.name">
          </div>
          <div class="input-block">
            <label>Описание роли</label>
            <textarea rows="6" v-model="role.description"></textarea>
          </div>
          <div class="input-block">
            <div @click="addRole" class="btn btn-sm btn-success">Добавить</div>
          </div>

          <div class="roles--list">
            <div class="section-title">Роли</div>
            <div class="roles--items">
              <div v-for="(item, index) in roleList" :key="index" class="item">
                <div class="name">
                  {{item.name}} ({{item.description}})
                </div>
                <div class="actions">
                  <div @click="addPermInRoleFunc(item.name)" class="btn btn-sm btn-info">Добавить разрешений</div>
                  <div @click="addRoleInRoleFunc(item.name)" class="btn btn-sm btn-info">Добавить ролей</div>
                  <div @dblclick="deleteRole(item.name)" class="btn btn-sm btn-danger">Удалить</div>
                </div>
                <div class="assign">
                  <div class="perm-item" v-for="(assign, index) in item.children" :key="index">
                    {{assign.name}} ({{assign.description}}),
                    <span @dblclick="removeChild(item.name, assign.name, assign.type)" class="delete-text">Удалить</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="perms">
          <div class="input-block">
            <label>Название разрешения</label>
            <input type="text" v-model="perm.name">
          </div>
          <div class="input-block">
            <label>Описание разрешения</label>
            <textarea rows="6" v-model="perm.description"></textarea>
          </div>
          <div class="input-block">
            <div @click="addPermission" class="btn btn-sm btn-success">Добавить</div>
          </div>

          <div class="perms--list">
            <div class="section-title">Разрешения</div>
            <div class="perms--items">
              <div v-for="(item, index) in permList" :key="index" class="item">
                <div class="name">
                  {{item.name}} ({{item.description}}),
                  <span @dblclick="deletePermission(item.name)" class="delete-text">Удалить</span>
                </div>
              </div>
            </div>
          </div>
        </div>

          <popup
              :closeButton="addPermPopup.closeButton"
              :actionButton="addPermPopup.actionButton"
              :actionClass="addPermPopup.actionClass"
              :show="addPermPopup.show"
              @closePopup="closeAddPermPopup"
              @actionPopup="addPermPopupSubmit"
          >
            <div slot="header">Добавить разрешение в {{addPermInRole}}</div>
            <div slot="body">
              <select v-model="permsModel">
                <option v-for="(item, index) in freePerm" :key="index" :value="item.name">{{item.name}} ({{item.description}})</option>
              </select>
            </div>
          </popup>

        <popup
            :closeButton="addRolePopup.closeButton"
            :actionButton="addRolePopup.actionButton"
            :actionClass="addRolePopup.actionClass"
            :show="addRolePopup.show"
            @closePopup="closeAddRolePopup"
            @actionPopup="addRolePopupSubmit"
        >
          <div slot="header">Добавить роль в {{addPermInRole}}</div>
          <div slot="body">
            <select v-model="roleModel">
              <option v-for="(item, index) in freeRole" :key="index" :value="item.name">{{item.name}} ({{item.description}})</option>
            </select>
          </div>
        </popup>


      </div>
    </template>
  </panel-main-template>
</template>

<script>
// @ is an alias to /src

import PanelMainTemplate from "../../components/panel/MainTemplate";
import axios from "axios";
import Popup from "@/components/Popup";
// import axios from "axios";

// const permissions = new Permissions();

export default {
  name: "Roles",
  components: {
    Popup,
    PanelMainTemplate
  },
  data() {
    return {
      roleList: [],
      permList: [],
      role: {
        name: '',
        description: ''
      },
      perm: {
        name: '',
        description: ''
      },
      addPermInRole: null, // РОЛЬ в которую будет добавляться permission
      permsModel: '',
      roleModel: '',
      addPermPopup: {
        show: false,
        closeButton: "Закрыть",
        actionButton: "Подтвердить",
        actionClass: "btn-success"
      },
      addRolePopup: {
        show: false,
        closeButton: "Закрыть",
        actionButton: "Подтвердить",
        actionClass: "btn-success"
      }
    };
  },
  computed: {
    freePerm(){
      let result = [];
      if(this.addPermInRole){
        for(let role in this.roleList){
          if(this.roleList[role].name === this.addPermInRole){
            for(let all in this.permList){
              let z = false;
              for(let rPerm in this.roleList[role].children){
                if(this.roleList[role].children[rPerm].name == this.permList[all].name){
                  z = true;
                }
              }
              if(!z){
                result.push(this.permList[all]);
              }
            }
          }
        }
      }else{
        for(let key in this.permList){
          result.push(this.permList[key]);
        }
      }
      return result;
    },
    freeRole(){
      let result = [];
      for(let role in this.roleList){
        if(this.roleList[role].name !== this.addPermInRole){
          let z = false;
          // Еще один поиск уже внутри присвоенных ролей в ролях
          for(let roleTwo in this.roleList){
            if(this.roleList[roleTwo].name === this.addPermInRole){
              for(let rPerm in this.roleList[roleTwo].children){
                if(this.roleList[roleTwo].children[rPerm].name === this.roleList[role].name){
                  z = true;
                }
              }
            }
          }
          if(!z){
            result.push(this.roleList[role]);
          }
        }
      }
      return result;
    }
  },
  methods: {
    closeAddPermPopup(){
      this.addPermPopup.show = false;
    },
    showAddPermPopup(){
      this.addPermPopup.show = true;
    },
    addPermPopupSubmit(){
      this.$store.dispatch("startPreloader");
      let formData = new FormData();
      formData.append("role_name", this.addPermInRole);
      formData.append("perm_name", this.permsModel);
      axios
          .post(`/role/add-perm-in-role`, formData)
          .then(resp => {
            if (resp.data.result === "ok") {
              this.getAll();
              this.closeAddPermPopup();
            }
            this.$store.dispatch("stopPreloader");
          })
          .catch(err => {
            this.$store.dispatch("stopPreloader");
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
          });
    },
    addPermInRoleFunc(name){
      this.addPermInRole = name;
      this.showAddPermPopup();
    },
    closeAddRolePopup(){
      this.addRolePopup.show = false;
    },
    showAddRolePopup(){
      this.addRolePopup.show = true;
    },
    addRoleInRoleFunc(name){
      this.addPermInRole = name;
      this.showAddRolePopup();
    },
    addRolePopupSubmit(){
      this.$store.dispatch("startPreloader");
      let formData = new FormData();
      formData.append("role_name", this.addPermInRole);
      formData.append("child_role", this.roleModel);
      axios
          .post(`/role/add-role-in-role`, formData)
          .then(resp => {
            if (resp.data.result === "ok") {
              this.getAll();
              this.closeAddRolePopup();
            }
            this.$store.dispatch("stopPreloader");
          })
          .catch(err => {
            this.$store.dispatch("stopPreloader");
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
          });
    },
    addRole(){
      this.$store.dispatch("startPreloader");
      let formData = new FormData();
      formData.append("name", this.role.name);
      formData.append("description", this.role.description);
      axios
          .post(`/role/add-role`, formData)
          .then(resp => {
            if (resp.data.result === "ok") {
              this.getAll();
            }
            this.$store.dispatch("stopPreloader");
          })
          .catch(err => {
            this.$store.dispatch("stopPreloader");
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
          });
    },
    removeChild(roleName, childName, type){
      this.$store.dispatch("startPreloader");
      let formData = new FormData();
      formData.append("role_name", roleName);
      formData.append("child_name", childName);
      formData.append("child_type", type);
      axios
          .post(`/role/remove-child`, formData)
          .then(resp => {
            if (resp.data.result === "ok") {
              this.getAll();
            }
            this.$store.dispatch("stopPreloader");
          })
          .catch(err => {
            this.$store.dispatch("stopPreloader");
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
          });
    },
    deleteRole(name){
      this.$store.dispatch("startPreloader");
      let formData = new FormData();
      formData.append("name", name);
      axios
          .post(`/role/remove-role`, formData)
          .then(resp => {
            if (resp.data.result === "ok") {
              this.getAll();
            }
            this.$store.dispatch("stopPreloader");
          })
          .catch(err => {
            this.$store.dispatch("stopPreloader");
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
          });
    },
    addPermission(){
      this.$store.dispatch("startPreloader");
      let formData = new FormData();
      formData.append("name", this.perm.name);
      formData.append("description", this.perm.description);
      axios
          .post(`/role/add-permission`, formData)
          .then(resp => {
            if (resp.data.result === "ok") {
              this.getAll();
            }
            this.$store.dispatch("stopPreloader");
          })
          .catch(err => {
            this.$store.dispatch("stopPreloader");
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
          });
    },
    deletePermission(name){
      this.$store.dispatch("startPreloader");
      let formData = new FormData();
      formData.append("name", name);
      axios
          .post(`/role/remove-permission`, formData)
          .then(resp => {
            if (resp.data.result === "ok") {
              this.getAll();
            }
            this.$store.dispatch("stopPreloader");
          })
          .catch(err => {
            this.$store.dispatch("stopPreloader");
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
          });
    },
    getAll(){
      this.$store.dispatch("startPreloader");
      axios
          .post(`/role/get-all`)
          .then(resp => {
            this.roleList = resp.data.roles;
            this.permList = resp.data.perm;
            this.$store.dispatch("stopPreloader");
          })
          .catch(err => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
            this.$store.dispatch("stopPreloader");
          });
    }
  },
  created() {
    this.getAll();
  }
};
</script>

<style scoped lang="less">
.roles--container{
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  flex-direction: row;

  .roles{
    width: 48%;

    .roles--items{
      margin-top: 10px;

      .item{
        margin-top: 15px;
        border-top: 1px solid gray;
        padding-top: 4px;
        .name{
          font-size: 16px;
          font-weight: 700;
        }
        .assign{
          padding-left: 15px;
          .perm-item{
            margin-top: 5px;
          }
        }
      }
    }
  }
  .perms{
    width: 48%;

    .perms--items{

      .item{
        margin-top: 5px;
      }
    }
  }
}
.delete-text{
  color: #F97979;
  text-decoration: underline;
  cursor: pointer;
}
@media (max-width: 700px) {
  .roles--container{
    flex-wrap: wrap;

    .roles{
      width: 100%;

      .roles--list{
        margin-top: 30px;
      }
    }
    .perms{
      margin-top: 60px;
      width: 100%;
    }
  }
}
</style>
