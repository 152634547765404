<template>
  <div class="user-support--container">
    <div class="support--more">
      <div @click="loadMore" class="btn btn-sm btn-success">Загрузить еще</div>
    </div>
    <div class="support--messages">
      <div 
        v-for="item in dataReverse" 
        :key="item.id" 
        class="item" 
        :class="{left: item.is_admin == 0, right: item.is_admin == 1}"
      >
        <div>
          <div class="name">
            <span v-if="item.is_admin == 1">Я</span>
            <span v-if="item.is_admin == 0">Клиент</span>
          </div>
          <div class="time">
            {{datetimeToRus(item.date)}}
            <span v-if="item.is_admin == 1 && item.seen == 1"><f-awesome icon="check"></f-awesome></span>
          </div>
          <div class="text" v-html="item.message"></div>
          <div v-if="item.is_admin == 1" class="edit text-right mrg-t-10">
            <div @click="editMessage(item.id)" class="btn btn-sm btn-outline-info">Изменить</div>
          </div>
        </div>
      </div>
    </div>
    <div class="input-block mrg-t-25">
      <label>Текст сообщения</label>
      <wysiwyg
          v-model="messageModel.model"
          rows="6"
          placeholder="Введите текст..."
      ></wysiwyg>
      <div class="form-error">{{messageModel.error}}</div>
      <div class="hint">Максимальная длина сообщения 1000 символов</div>
    </div>
    <div class="input-block">
      <div 
        v-if="editMessageId > 0" 
        @click="editMessageSubmit"
        class="btn btn-sm btn-success mrg-t-10"
      >
        Изменить
      </div>
      <div 
        @click="sendMessage" 
        class="btn btn-sm btn-success mrg-t-10"
      >
        Отправить
      </div>
      <div @click="checkSeen" class="btn btn-sm btn-orange mrg-t-10">Отметить, как прочитанные</div>
      <div @click="attach" class="btn btn-sm btn-info mrg-t-10">Закреп</div>
      <div @click="updateList" class="btn btn-sm btn-info mrg-t-10"><f-awesome icon="sync"></f-awesome></div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Formatter from "@/components/libraries/Formatter";

const formatter = new Formatter();

export default {
  name: "UserSupport",
  components: {},
  data() {
    return {
      data: [],
      limit: 6,
      page: 0,
      messageModel: {model: '', error: ''},
      getMessageMode: 'more', // more|new - Догружает новые|Обновляет список полностью

      editMessageId: 0,
    };
  },
  props: {
    id: Number
  },
  watch: {
    id(){
      this.setModeNew();
      this.getMessages();
    }
  },
  computed: {
    dataReverse(){
      let result = [];
      if(this.data.length){
        for(let key in this.data){
          result.push(this.data[key]);
        }
        result.reverse();
      }
      return result;
    }
  },
  methods: {
    editMessageSubmit(){
      if (this.validateData) {
        return false;
      }
      this.$store.dispatch("startPreloader");
      let formData = new FormData();
      formData.append("message", this.messageModel.model);
      formData.append("message_id", this.editMessageId);
      axios
          .post(`/support/update-admin`, formData)
          .then(() => {
            this.messageModel.model = '';
            this.setModeNew();
            this.getMessages();
            this.$emit('update');
            this.editMessageId = 0;
            this.$store.dispatch("addNotification", {
              text: 'Сообщение изменено',
              time: 4,
              color: "success"
            });
            this.$store.dispatch("stopPreloader");
          })
          .catch(err => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
            this.$store.dispatch("stopPreloader");
          });
    },
    editMessage(messageId){
      this.editMessageId = +messageId;

      for(let key in this.data){
        if(+this.data[key].id === +messageId){
          this.messageModel.model = this.data[key].message;
        }
      }
    },
    datetimeToRus(datetime){
      return formatter.datetimeToRus(datetime);
    },
    updateList(){
      this.setModeNew();
      this.getMessages();
    },
    setModeNew(){
      this.getMessageMode = 'new';
      this.page = 0;
    },
    checkSeen(){
      this.$store.dispatch("startPreloader");
      let formData = new FormData();
      formData.append("user_id", this.id);
      axios
          .post(`/support/set-seen`, formData)
          .then(resp => {
            if(resp.data.result == 'ok'){
              this.$store.dispatch("addNotification", {
                text: 'Успешно',
                time: 4,
                color: "success"
              });
            }

            this.$store.dispatch("stopPreloader");
          })
          .catch(err => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
            this.$store.dispatch("stopPreloader");
          });
    },
    sendMessage(){
      if (this.validateData) {
        return false;
      }
      this.$store.dispatch("startPreloader");
      let formData = new FormData();
      formData.append("message", this.messageModel.model);
      formData.append("user_id", this.id);
      axios
          .post(`/support/set-admin`, formData)
          .then(() => {
            this.messageModel.model = '';
            this.setModeNew();
            this.getMessages();
            this.$emit('update');
            this.$store.dispatch("addNotification", {
              text: 'Сообщение отправлено',
              time: 4,
              color: "success"
            });
            this.$store.dispatch("stopPreloader");
          })
          .catch(err => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
            this.$store.dispatch("stopPreloader");
          });
    },
    attach(){
      this.$store.dispatch("startPreloader");
      let formData = new FormData();
      formData.append("description", this.messageModel.model);
      formData.append("user_id", this.id);
      axios
          .post(`/support/set-attach`, formData)
          .then(() => {
            this.messageModel.model = '';
            this.$emit('update');
            this.$store.dispatch("addNotification", {
              text: 'Закреплено',
              time: 4,
              color: "success"
            });
            this.$store.dispatch("stopPreloader");
          })
          .catch(err => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
            this.$store.dispatch("stopPreloader");
          });
    },
    getMessages(){
      this.$store.dispatch("startPreloader");
      let formData = new FormData();
      formData.append("limit", this.limit);
      formData.append("page", this.page);
      formData.append("user_id", this.id);
      axios
          .post(`/support/get-messages`, formData)
          .then(resp => {
            if(this.getMessageMode == 'more'){
              if(resp.data.length){
                for(let key in resp.data){
                  this.data.push(resp.data[key]);
                }
              }else{
                this.$store.dispatch("addNotification", {
                  text: 'Нет сообщений',
                  time: 3,
                  color: "danger"
                });
              }
            }else if(this.getMessageMode == 'new'){
              this.data = resp.data;
            }

            this.$store.dispatch("stopPreloader");
          })
          .catch(err => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
            this.$store.dispatch("stopPreloader");
          });
    },
    loadMore(){
      this.page++;
      this.getMessageMode = 'more';
      this.getMessages();
    }
  },
  created() {
    this.getMessages();
  }
};
</script>

<style scoped lang="less">
.support--more{
  text-align: center;
}
.support--messages{
  .item{
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;

    &>div{
      width: 80%;
      padding: 10px;
      margin-top: 8px;
      border-radius: 10px;

      .name{
        font-size: 15px;
        font-weight: 700;
      }
      .time{
        font-size: 12px;
        color: #F97979;
      }
      .text{
        font-size: 14px;
        margin-top: 4px;
      }
    }
  }
  .item.left{
    justify-content: flex-start;

    &>div{
      background-color: #DBE5EA;
    }
  }
  .item.right{
    justify-content: flex-end;

    &>div{
      background-color: #8CD790;
    }
  }
}

</style>
