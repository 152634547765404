<template>
  <div class="user-statistics--container">
    <div>Кол-во записей за последние 30 дней: <b class="text-orange">{{data.enrolls_count}}</b></div>
    <div class="mrg-t-10">
      Всего потратил средств в сервисе: 
      <b class="text-orange">{{data.spent_all}} руб.</b>
    </div>
    <div class="mrg-t-10">Кол-во клиентов в базе: <b class="text-orange">{{data.clients_count}}</b></div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "UserStatistics",
  components: {},
  data() {
    return {
      data: {}
    };
  },
  props: {
    id: Number
  },
  watch: {
    id(){
      this.getInfo();
    }
  },
  computed: {},
  methods: {
    getInfo(){
      this.$store.dispatch("startPreloader");
      let formData = new FormData();
      formData.append("user_id", this.id);
      axios
          .post(`/users/get-statistics`, formData)
          .then(resp => {
            this.data = resp.data;
            this.$store.dispatch("stopPreloader");
          })
          .catch(err => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
            this.$store.dispatch("stopPreloader");
          });
    }
  },
  created() {
    this.getInfo();
  }
};
</script>

<style scoped lang="less">

</style>
