const bell = {
  state: {
    nextUpdate: 0,
    bells: []
  },
  mutations: {
    ADD_BELLS(state, notifications) {
      state.bells = notifications;
    },
    REMOVE(state) {
      state.bells = [];
    }
  },
  actions: {
    addBells({ commit, state }, notifications) {
      let nowDate = new Date();
      state.nextUpdate = nowDate.getTime() + 14 *1000;
      commit("ADD_BELLS", notifications);
    },
    removeBells({ commit }) {
      commit("REMOVE");
    }
  }
};

export default bell;
