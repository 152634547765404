import Vue from 'vue'
import Vuex from 'vuex'

import authorization from "./modules/authorization";
import preloader from "./modules/preloader";
import notificator from "./modules/notificator";
import bell from "./modules/bell";
import version from "@/store/modules/version";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    authorization,
    preloader,
    notificator,
    bell,
    version
  }
})
