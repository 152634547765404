<template>
  <panel-main-template>
    <template v-slot:page-title>
      Сервер
    </template>
    <template v-slot:page-content>
      <div class="server--container">
        <div class="left">
          <div class="shadow-card uptime">
            <div><b>Состояние сервера (последние 8 часов)</b></div>
            <div class="server--status-container">
              <simple-preloader :is-show="Object.keys(serverInfo).length === 0"></simple-preloader>
              <div v-if="Object.keys(serverInfo).length > 0">
                <div class="icon text-center mrg-t-15">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path fill="#E95420" d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm52.7 93c8.8-15.2 28.3-20.5 43.5-11.7 15.3 8.8 20.5 28.3 11.7 43.6-8.8 15.2-28.3 20.5-43.5 11.7-15.3-8.9-20.5-28.4-11.7-43.6zM87.4 287.9c-17.6 0-31.9-14.3-31.9-31.9 0-17.6 14.3-31.9 31.9-31.9 17.6 0 31.9 14.3 31.9 31.9 0 17.6-14.3 31.9-31.9 31.9zm28.1 3.1c22.3-17.9 22.4-51.9 0-69.9 8.6-32.8 29.1-60.7 56.5-79.1l23.7 39.6c-51.5 36.3-51.5 112.5 0 148.8L172 370c-27.4-18.3-47.8-46.3-56.5-79zm228.7 131.7c-15.3 8.8-34.7 3.6-43.5-11.7-8.8-15.3-3.6-34.8 11.7-43.6 15.2-8.8 34.7-3.6 43.5 11.7 8.8 15.3 3.6 34.8-11.7 43.6zm.3-69.5c-26.7-10.3-56.1 6.6-60.5 35-5.2 1.4-48.9 14.3-96.7-9.4l22.5-40.3c57 26.5 123.4-11.7 128.9-74.4l46.1 .7c-2.3 34.5-17.3 65.5-40.3 88.4zm-5.9-105.3c-5.4-62-71.3-101.2-128.9-74.4l-22.5-40.3c47.9-23.7 91.5-10.8 96.7-9.4 4.4 28.3 33.8 45.3 60.5 35 23.1 22.9 38 53.9 40.2 88.5l-46 .6z"/></svg>
                </div>
                <div v-if="serverInfo.server_status.result === 'error'" class="error text-danger mrg-t-10">
                  Ошибка получения данных: {{serverInfo.server_status.error_message}}
                </div>
                <div v-if="serverInfo.server_status.result === 'ok'" class="success">
                  <div class="disk">
                    <div><b>Диск</b></div>
                    <div>
                      Всего: {{serverInfo.server_status.data.disk.size}} GB / Занято: {{serverInfo.server_status.data.disk.used}} GB /
                      <b>Свободно: {{serverInfo.server_status.data.disk.free}} GB</b>
                    </div>
                  </div>
                  <div class="cpu mrg-t-10">
                    <div><b>CPU</b></div>
                    <div>
                      Max load: <b>{{parseFloat(serverInfo.server_status.data.cpu.top_load.toFixed(2))}}%</b> /
                      Avg load: <b>{{parseFloat(serverInfo.server_status.data.cpu.avg_load.toFixed(2))}}%</b>
                    </div>
                  </div>
                  <div class="network mrg-t-10">
                    <div><b>Сеть</b></div>
                    <div>
                      <div>Макс. входящий: <b>{{parseFloat(serverInfo.server_status.data.traffic_network.top_incoming.toFixed(2))}} мбит/с</b></div>
                      <div>Средний входящий: <b>{{parseFloat(serverInfo.server_status.data.traffic_network.avg_incoming.toFixed(2))}} мбит/с</b></div>
                      <div class="mrg-t-5">Макс. исходящий: <b>{{parseFloat(serverInfo.server_status.data.traffic_network.top_outgoing.toFixed(2))}} мбит/с</b></div>
                      <div>Средний исходящий: <b>{{parseFloat(serverInfo.server_status.data.traffic_network.avg_outgoing.toFixed(2))}} мбит/с</b></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="mrg-t-10">{{serverInfo.load_average}}</div>
            <div class="mrg-t-10">{{serverInfo.disk_usage}}</div>
            <div class="mrg-t-15 text-center">
              <div @click="showLogServerPopup" class="btn btn-sm btn-info">Показать логи сервера</div>
            </div>
          </div>
          <div class="shadow-card sms_balance">
            <div><b>Баланс sms.ru</b></div>
            <div class="mrg-t-10">{{serviceStat.sms_balance}} руб.</div>
          </div>
          <div v-if="serviceStat.blocked_ip" class="shadow-card blocked_ip">
            <div><b>Заблокированные IP</b></div>
            <div class="mrg-t-10 text-bold" v-if="serviceStat.blocked_ip.length === 0">
              -
            </div>
            <div class="mrg-t-10 text-bold" v-if="serviceStat.blocked_ip.length > 0">
              <div
                  v-for="(item, index) in serviceStat.blocked_ip"
                  :key="index"
                  class="mrg-t-5"
              >
                <div>{{item.ip}}</div>
                <div>
                  <div @click="deleteBlockIp(item.ip)" class="btn btn-sm btn-danger">Удалить</div>
                </div>
              </div>
            </div>
            <div class="mrg-t-10">
              <div @click="showBlockIpPopup" class="btn btn-sm btn-info">Добавить в блокировку</div>
            </div>
          </div>
          <div v-if="serviceStat.email_queue_status" class="alert alert-sm alert-danger">
            Кажется есть проблемы с отправкой E-mail
          </div>
          <div v-else class="alert alert-sm alert-success">
            Сервер E-mail рассылок работает нормально
          </div>

          <div v-if="serviceStat.sms_queue_status" class="alert alert-sm alert-danger">
            Имеется 5 или более СМС с ошибкой отправки
          </div>
          <div v-else class="alert alert-sm alert-success">
            Сервер СМС рассылок работает нормально
          </div>
        </div>

        <div class="right">
          <div v-if="serviceStat.user_top_enroll" class="shadow-card expenses">
            <div><b>Сумма пополнений пакетов записей</b></div>
            <div class="mrg-t-10">
              Прошлый месяц: {{serviceStat.user_top_enroll.prev_month.sum}} руб.<br>
              Текущий месяц: {{serviceStat.user_top_enroll.current_month.sum}} руб.
            </div>
          </div>
          <div class="shadow-card active">
            <div>Пользователей вчера (которых еще не было сегодня): {{serviceStat.yesterday_active}}</div>
            <div class="mrg-t-10">Пользователей заходило сегодня: {{serviceStat.today_active}}</div>
          </div>
          <div v-if="serviceStat.enroll_count" class="shadow-card expenses">
            <div><b>Количество созданных записей</b></div>
            <div class="mrg-t-10">
              Вчера: {{serviceStat.enroll_count.yesterday}}<br>
              Сегодня: {{serviceStat.enroll_count.today}}
            </div>
          </div>
          <div class="shadow-card become_constant">
            <div><b>Кол-во постоянных пользователей</b></div>
            <div class="mrg-t-10 text-bold">
              {{serviceStat.constant_clients}}
            </div>
          </div>
          <div class="shadow-card clients_count">
            <div><b>Кол-во клиентов пользователей в БД</b></div>
            <div class="mrg-t-10 text-bold">
              {{serviceStat.clients_count}}
            </div>
          </div>
        </div>

      </div>


      <popup
          :closeButton="logServerPopup.closeButton"
          :show="logServerPopup.show"
          :size="'lg'"
          @closePopup="closeLogServerPopup"
      >
        <div slot="header">Последние логи сервера</div>
        <div slot="body">
          <div class="last-logs-container">
            <div class="log-line" v-for="(item, index) in lastLogs" :key="index">{{item}}</div>
          </div>
        </div>
      </popup>

      <popup
          :closeButton="blockIpPopup.closeButton"
          :show="blockIpPopup.show"
          :action-button="blockIpPopup.action"
          :action-class="blockIpPopup.actionClass"
          @closePopup="closeBlockIpPopup"
          @actionPopup="blockIpSubmit"
      >
        <div slot="header">Добавление IP в блокировку</div>
        <div slot="body">
          <div class="input-block">
            <label>IP для блокировки</label>
            <input type="text" v-model="blockIpModel.ip">
          </div>
          <div class="input-block">
            <label>На сколько минут заблокировать</label>
            <input type="text" v-model="blockIpModel.minutes">
          </div>
        </div>
      </popup>


    </template>
  </panel-main-template>
</template>

<script>
// @ is an alias to /src

import PanelMainTemplate from "../../components/panel/MainTemplate";
import axios from "axios";
import Popup from "../../components/Popup";
import SimplePreloader from "../../components/SimplePreloader.vue";
// import axios from "axios";

// const permissions = new Permissions();

export default {
  name: "Journal",
  components: {
    SimplePreloader,
    Popup,
    PanelMainTemplate
  },
  data() {
    return {
      serviceStat: {},
      serverInfo: {},
      lastLogs: [],
      logServerPopup: {
        show: false,
        closeButton: "Отмена"
      },
      blockIpPopup: {
        show: false,
        closeButton: "Закрыть",
        action: 'Добавить',
        actionClass: 'btn-success'
      },
      blockIpModel: {
        ip: '',
        minutes: ''
      }
    };
  },
  computed: {},
  methods: {
    deleteBlockIp(ip){
      this.$store.dispatch("startPreloader");
      let formData = new FormData();
      formData.append('ip', ip);

      axios
          .post(`/data-service/delete-block-ip`, formData)
          .then(() => {
            this.$store.dispatch("addNotification", {
              text: 'IP успешно разблокирован',
              time: 3,
              color: "success"
            });
            this.getInfo();
            this.$store.dispatch("stopPreloader");
          })
          .catch((err) => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
            this.$store.dispatch("stopPreloader");
          });
    },
    blockIpSubmit(){
      this.$store.dispatch("startPreloader");
      let formData = new FormData();
      formData.append('ip', this.blockIpModel.ip);
      formData.append('minutes', this.blockIpModel.minutes);
      axios
          .post(`/data-service/manual-block-ip`, formData)
          .then(() => {
            this.closeBlockIpPopup();
            this.$store.dispatch("addNotification", {
              text: 'IP успешно заблокирован',
              time: 3,
              color: "success"
            });
            this.getInfo();
            this.$store.dispatch("stopPreloader");
          })
          .catch((err) => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
            this.$store.dispatch("stopPreloader");
          });
    },
    showBlockIpPopup(){
      this.blockIpPopup.show = true;
    },
    closeBlockIpPopup(){
      this.blockIpPopup.show = false;
    },
    showLogServerPopup(){
      this.$store.dispatch("startPreloader");
      axios
          .post(`/data-service/last-log`)
          .then(resp => {
            this.lastLogs = resp.data;
            this.logServerPopup.show = true;
            this.$store.dispatch("stopPreloader");
          })
          .catch((err) => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
            this.$store.dispatch("stopPreloader");
          });
    },
    closeLogServerPopup(){
      this.lastLogs = [];
      this.logServerPopup.show = false;
    },
    getServiceStat(){
      this.$store.dispatch("startPreloader");
      axios
          .post(`/data-service/service-stat`)
          .then(resp => {
            this.serviceStat = resp.data;
            this.$store.dispatch("stopPreloader");
          })
          .catch((err) => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
            this.$store.dispatch("stopPreloader");
          });

    },
    getServerInfo(){
      axios
          .post(`/data-service/server-info`)
          .then(resp => {
            this.serverInfo = resp.data;
          })
          .catch((err) => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
          });

      }
  },
  created() {
    this.getServiceStat();
    setTimeout(() => this.getServerInfo(), 2000);
  }
};
</script>

<style scoped lang="less">
.server--container{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-direction: row;

  &>.left, &>.right{
    &>div{
      margin-top: 15px;
    }
    width: 49%;
  }
}
.last-logs-container{
  .log-line{
    margin-top: 2px;
    color: gray;
  }
}
.server--status-container{
  position: relative;
  min-height: 100px;

  .icon{
    svg{
      width: 30px;
      color: orange;
    }
  }
}
@media (max-width: 700px) {
  .server--container{
    flex-wrap: wrap;

    &>.left, &>.right{
      width: 100%;
    }
  }
}
</style>
