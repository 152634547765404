<template>
  <div class="user-search--container">
    <div class="input-block">
      <label>Искать по</label>
      <select v-model="searchType">
        <option value="email">e-mail</option>
        <option value="user_id">user ID</option>
        <option value="inn">ИНН</option>
        <option value="company_hash">Хэш компании</option>
        <option value="company_id">ID компании</option>
      </select>
    </div>
    <div class="input-block">
      <label>Введите поисковой запрос</label>
      <input type="text" v-model="searchStr">
    </div>
    <div class="input-block">
      <div @click="searchUser" class="btn btn-sm btn-success">Найти</div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "UserSearch",
  components: {},
  data() {
    return {
      searchStr: '',
      searchType: 'email'
    };
  },
  props: {

  },
  watch: {

  },
  computed: {},
  methods: {
    searchUser(){
      this.$store.dispatch("startPreloader");
      let formData = new FormData();
      formData.append("search_str", this.searchStr);
      formData.append("search_type", this.searchType);
      axios
          .post(`/users/search-id`, formData)
          .then(resp => {
            if(resp.data.result == 'ok'){
              this.$emit('userId', resp.data.data);
            }else if(resp.data.result == 'error'){
              this.$store.dispatch("addNotification", {
                text: resp.data.text,
                time: 4,
                color: "danger"
              });
            }
            this.$store.dispatch("stopPreloader");
          })
          .catch(err => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
            this.$store.dispatch("stopPreloader");
          });
    }
  },
  created() {

  }
};
</script>

<style scoped lang="less">

</style>
