<template>
  <div></div>
</template>

<script>

export default {
  name: "SetManifest",
  components: {},
  data() {
    return {
      
    };
  },
  props: {
    
  },
  watch: {
    
  },
  
  methods: {
    setManifest(){
      let manifestJSON =
      {
        "name": "EnrollerAdmin",
        "short_name": "EnrollerAdmin",
        "scope": window.location.protocol +'//' +window.location.host,
        "start_url": window.location.protocol +'//' +window.location.host,
        "display": "standalone",
        "icons":[
            {
                "src":"https://lk.enroller.ru/img/icons/android-icon-96x96.png",
                "sizes":"96x96",
                "type":"image/png"
            },
            {
                "src":"https://lk.enroller.ru/img/icons/android-chrome-192x192.png",
                "sizes":"192x192",
                "type":"image/png"
            },
            {
                "src":"https://lk.enroller.ru/img/icons/apple-icon-114x114.png",
                "sizes":"114x114",
                "type":"image/png"
            },
            {
                "src":"https://lk.enroller.ru/img/icons/apple-icon-120x120.png",
                "sizes":"120x120",
                "type":"image/png"
            },
            {
                "src":"https://lk.enroller.ru/img/icons/apple-icon-144x144.png",
                "sizes":"144x144",
                "type":"image/png"
            },
        ],
        themeColor: "#38424e",
        theme_color: "#38424e",
        msTileColor: "#000",
        background_color: "#38424e",
        backgroundColor: "#38424e",
        manifestOptions: {
          background_color: "#38424e",
          backgroundColor: "#38424e",
          themeColor: "#38424e",
          theme_color: "#38424e",
        }
      };

      const stringManifest = JSON.stringify(manifestJSON);
      const blob = new Blob([stringManifest], {type: 'application/json'});
      const manifestURL = URL.createObjectURL(blob);
      document.querySelector('link[rel="manifest"]').setAttribute('href', manifestURL);
      document.querySelector('meta[name="theme-color"]').setAttribute('content', '#38424e');
    }
  },
  created() {
    this.setManifest();
  }
};
</script>

<style scoped lang="less">

</style>
