<template>
  <div class="paginator--container">
    <div class="paginator--item" v-for="item in data" :key="item">
      <div
        v-if="item !== '..'"
        class="paginator--number"
        @click="setPage(item)"
        :class="{ active: +item == now }"
      >
        {{ item }}
      </div>
      <div v-if="item === '..'" class="paginator--dots">{{ item }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Paginator",
  components: {},
  data() {
    return {
      data: []
    };
  },
  props: {
    pages: Number, // Кол-во страниц
    now: Number // Текущая страница
  },
  watch: {
    pages: function() {
      this.setPaginator();
    },
    now: function() {
      this.setPaginator();
    }
  },
  computed: {},
  methods: {
    setPaginator() {
      this.data = [];
      for (let i = 1; i <= +this.pages; i++) {
        if (
          i <= 2 ||
          (i >= +this.now - 1 && i <= +this.now + 1) ||
          i >= +this.pages - 1
        ) {
          if (i !== 1 && +this.data[this.data.length - 1] + 1 !== i) {
            this.data[this.data.length] = "..";
          }
          this.data[this.data.length] = i;
        } else {
          continue;
        }
      }
    },
    setPage(val) {
      this.$emit("setPage", val);
    }
  },
  created() {
    this.setPaginator();
  }
};
</script>

<style scoped lang="less">
.paginator--container {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  flex-direction: row;

  .paginator--item {
    .paginator--number {
      cursor: pointer;
    }
    .paginator--number:hover:not(.active) {
      background-color: #ddd;
    }
    & > div {
      width: 35px;
      height: 35px;
      line-height: 35px;
      text-align: center;
    }
    & > div.active {
      background-color: #f87979;
    }
  }
}
</style>
