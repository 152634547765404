<template>
  <div class="user-companies--container">
    <div v-if="common" class="user-companies--main">
      Основная ссылка:
      <a class="text-link" :href="common" target="_blank">{{common}}</a>
    </div>
    <div v-if="companies.length" class="user-companies--companies mrg-t-25">
      <div class="item mrg-t-20" v-for="item in companies" :key="item.id">
        <div class="item-company-data">
          <div>id: {{item.id}}</div>
          <div>name: {{item.name}}</div>
          <div>address: {{item.address}}</div>
          <div>city: {{item.city}}</div>
          <div>link: <a class="text-link" :href="item.link" target="_blank">{{item.link}}</a></div>
          <div><div @click="diagnostic(item.id)" class="btn btn-sm btn-orange">Диагностика</div></div>
        </div>
        <div class="item-company-services  mrg-t-10">
          <h2 class="cursor-pointer" @click="item.show_cats = !item.show_cats">Категории и услуги</h2>

          <div v-show="item.show_cats" class="item-company-services-list">
            <div class="cat-item" v-for="cat in item.services" :key="cat.id">
              <div @click="cat.show_services = !cat.show_services" class="cat-item--data cursor-pointer">
                <div><b>id: {{cat.id}}</b></div>
                <div><b>name: {{cat.name}}</b></div>
                <div v-if="cat.deleted == 1">
                  <b class="text-orange">Удалена</b>
                </div>
              </div>
              <div v-show="cat.show_services" class="serv-item--data">
                <div class="serv-item" v-for="serv in cat.services" :key="serv.id">
                  <div>id: {{serv.id}}</div>
                  <div>name: {{serv.name}}</div>
                  <div>price: {{serv.price}}</div>
                  <div>time: {{serv.time}}</div>
                  <div>hide: {{serv.hide}}</div>
                  <div v-if="serv.deleted == 1">
                    <b class="text-orange">Удалена</b>
                  </div>

                  <div v-if="serv.subcategory == 1 && serv.modification.length" class="modif-item--data">
                    <div class="modif-item" v-for="modif in serv.modification" :key="modif.id">
                      <div>id: {{modif.id}}</div>
                      <div>name: {{modif.name}}</div>
                      <div>price: {{modif.price}}</div>
                      <div>time: {{modif.time}}</div>
                      <div>hide: {{modif.hide}}</div>
                      <div v-if="modif.deleted == 1">
                        <b class="text-orange">Удалена</b>
                      </div>
                    </div>
                    <div v-if="!serv.modification.length" class="text-orange">Модификации отсутствуют</div>
                  </div>

                </div>
                <div v-if="!cat.services.length" class="text-orange">Услуги отсутствуют</div>

              </div>
            </div>
          </div>
          <div v-if="!item.services.length" class="text-orange text-center">Нет категорий и услуг</div>
        </div>
        <div class="item-company-staff-list mrg-t-20">
          <h2 class="cursor-pointer" @click="item.show_staffs = !item.show_staffs">Сотрудники</h2>

          <div class="item-company-staff-list">
            <div class="item-staff" v-for="staff in item.staffs" :key="staff.id">
              <div v-show="item.show_staffs" class="item-staff--data">
                <div>id: {{staff.id}}</div>
                <div>name: {{staff.name}}</div>
                <div>hide: {{staff.hide}}</div>
                <div>
                  Расписание (50 дней):
                  <span v-for="(sch, index) in staff.schedule" :key="index">/{{sch}}</span>
                </div>
                <div>
                  Услуги:
                  <span v-for="serv in staff.staffToServices" :key="serv.id">
                    /s:{{serv.service_id}},m:{{serv.modification_id}}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div v-if="!item.staffs.length" class="text-orange text-center">Нет сотрудников</div>
        </div>
      </div>
    </div>
    <div v-else>
      Нет компаний
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "UserCompanies",
  components: {},
  data() {
    return {
      common: null,
      companies: []
    };
  },
  props: {
    id: Number
  },
  watch: {
    id(){
      this.getInfo();
    }
  },
  computed: {},
  methods: {
    filteredGetData(data){
      let result = data;
      for(let key in result){
        result[key].show_cats = false;
        result[key].show_staffs = false;
        for(let cat in result[key].services){
          result[key].services[cat].show_services = false;
        }
      }
      return result;
    },
    diagnostic(companyId){
      this.$store.dispatch("startPreloader");
      let formData = new FormData();
      formData.append('company', companyId);
      formData.append('user_id', this.id);
      axios
          .post(`/users/diagnostics`, formData)
          .then(resp => {
            if(resp.data.result == 'ok'){
              this.$store.dispatch("addNotification", {
                text: 'Проблем не обнаружено',
                time: 3,
                color: "success"
              });
            }else if(resp.data.result == 'error'){
              this.$store.dispatch("addNotification", {
                text: resp.data.message,
                time: 8,
                color: "danger"
              });
            }
            this.$store.dispatch("stopPreloader");
          })
          .catch(err => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
            this.$store.dispatch("stopPreloader");
          });
    },
    getInfo(){
      this.$store.dispatch("startPreloader");
      let formData = new FormData();
      formData.append("user_id", this.id);
      axios
        .post(`/users/get-companies`, formData)
        .then(resp => {
          this.common = resp.data.common;
          this.companies = this.filteredGetData(resp.data.company);
          this.$store.dispatch("stopPreloader");
        })
        .catch(err => {
          this.$store.dispatch("addNotification", {
            text: err.response.data.message,
            time: 6,
            color: "danger"
          });
          this.$store.dispatch("stopPreloader");
        });
    }
  },
  created() {
    this.getInfo();
  }
};
</script>

<style scoped lang="less">
.user-companies--companies{
  .item{
    background-color: #F0F4F9;
    padding: 9px;
    box-sizing: border-box;

    .item-company-data{
      &>div{
        margin-top: 4px;
      }
    }

    .item-company-services{

      .item-company-services-list{

        .cat-item{
          margin-top: 14px;

          .cat-item--data{

          }
          .serv-item--data{
            margin-top: 8px;
            padding-left: 20px;
            box-sizing: border-box;

            .serv-item{
              margin-top: 8px;
            }

            .modif-item--data{
              margin-top: 8px;
              padding-left: 20px;

              .modif-item{
                margin-top: 8px;
              }
            }
          }

        }
      }
    }
  }

  .item-company-staff-list{

    .item-company-staff-list{

      .item-staff{
        margin-top: 10px;
        .item-staff--data{

        }
      }
    }
  }
}
</style>
