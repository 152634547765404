<template>
  <div class="login">
    <main-header></main-header>

    <div class="login--container">
      <div class="container">
        <div class="login--form-container">
          <div class="auth-title">
            <h3>Войдите</h3>
          </div>
          <form @submit.prevent="login">
            <div class="input-block">
              <label for="login-email">Адрес электронной почты</label>
              <input
                type="email"
                @focus="clearError"
                v-model="email"
                id="login-email"
              />
              <div class="form-error">{{ errorText }}</div>
            </div>
            <div class="input-block">
              <label for="login-password">Пароль</label>
              <input
                @focus="clearError"
                type="password"
                v-model="password"
                id="login-password"
              />
              <div class="form-error"></div>
            </div>
            <div class="input-block">
              <button type="submit" class="btn btn-sm btn-orange">Войти</button>
            </div>
          </form>

        </div>
      </div>
    </div>

    <main-footer></main-footer>
  </div>
</template>

<script>
// @ is an alias to /src
import MainHeader from "../components/MainHeader";
import MainFooter from "../components/MainFooter";
import { mapState } from "vuex";

export default {
  name: "Login",
  components: {
    MainFooter,
    MainHeader
  },
  data() {
    return {
      email: "",
      password: "",
      errorText: "",
    };
  },
  computed: {
    ...mapState({
      userData: state => state.authorization.userData
    })
  },
  methods: {
    login() {
      let email = this.email;
      let password = this.password;
      // this.$store.dispatch("startPreloader");
      // На всякий случай, для особо одаренных, сохраним это
      localStorage.setItem("confirmEmail", email);
      this.$store
        .dispatch("login", { email, password })
        .then(() => {
          this.$store.dispatch("stopPreloader");
          this.$router.push("/");
        })
        .catch(err => {
          this.errorText = err.message;
          this.$store.dispatch("stopPreloader");
        });
    },
    clearError() {
      this.errorText = "";
    }
  },
  created() {
    if (this.userData) {
      this.$router.push("/");
    }
  }
};
</script>

<style scoped lang="less">
.login--form-container {
  width: 500px;
  margin: 0 auto 60px auto;
}
@media (max-width: 700px) {
  .login--form-container {
    width: 98%;
  }
}
</style>
