<template>
  <div class="user-config--container">
    <form @submit.prevent="saveData">
      <div v-if="formData.main_config" class="user-config--main-config">
        <div class="text-center text-orange">MAIN CONFIG</div>
        <div v-for="(item, key, index) in formData.main_config" :key="index" class="input-block">
          <label>{{key}}</label>
          <input type="text" v-model="formData.main_config[key]">
        </div>
      </div>
      <div v-if="formData.company_config" class="user-config--company">
        <div v-for="(item, key, index) in formData.company_config" :key="index" class="user-config--company-item">
          <div class="text-center text-orange">COMPANY CONFIG</div>
          <div v-for="(itemV, keyV, index) in formData.company_config[key]" :key="index" class="input-block">
            <label>{{keyV}}</label>
            <input type="text" v-model="formData.company_config[key][keyV]">
          </div>
        </div>
      </div>
      <div class="input-block">
        <button type="submit" class="btn btn-sm btn-success">
          Сохранить
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "UserConfig",
  components: {},
  data() {
    return {
      formData: {}
    };
  },
  props: {
    id: Number
  },
  watch: {
    id(){
      this.getConfig();
    }
  },
  computed: {},
  methods: {
    saveData(){
      let formData = new FormData();
      for (let key in this.formData.main_config) {
        formData.append(key, this.formData.main_config[key]);
      }
      formData.append('configCompany', JSON.stringify(this.formData.company_config));
      axios
          .post(`/users/set-config`, formData)
          .then(resp => {
            if (resp.data.result == "ok") {
              this.$store.dispatch("addNotification", {
                text: "Данные успешно сохранены",
                time: 4,
                color: "success"
              });
            }
            this.$store.dispatch("stopPreloader");
          })
          .catch(err => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
            this.$store.dispatch("stopPreloader");
          });
    },
    getConfig(){
      this.$store.dispatch("startPreloader");
      let formData = new FormData();
      formData.append("user_id", this.id);
      axios
        .post(`/users/get-config`, formData)
        .then(resp => {
          this.formData = resp.data;
          this.$store.dispatch("stopPreloader");
        })
        .catch(err => {
          this.$store.dispatch("addNotification", {
            text: err.response.data.message,
            time: 6,
            color: "danger"
          });
          this.$store.dispatch("stopPreloader");
        });
    }
  },
  created() {
    this.getConfig();
  }
};
</script>

<style scoped lang="less">

</style>
