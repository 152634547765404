<template>
  <div class="user-actions--container">
    <hr>
    <div class="user-actions--email mrg-t-20">
      <div class="section-title">Изменение e-mail юзера</div>
      <div class="input-block mrg-t-10">
        <label>Новый e-mail пользователя</label>
        <input type="text" v-model="newEmail">
      </div>
      <div class="input-block">
        <div @dblclick="editEmail" class="btn btn-sm btn-success">Изменить</div>
      </div>
    </div>
    <br>
    <hr>
    <div class="user-actions--get-token mrg-t-20">
      <div class="section-title">Получить token входа для данного юзера</div>
      <div @click="getToken" class="btn btn-sm btn-info mrg-t-10">Получить токен входа</div>
      <br><br>
      <div>token: {{token}}</div>
    </div>
    <br>
    <hr>
    <div style="text-align: center" class="user-actions--remove">
      <div @dblclick="deleteUser" class="btn btn-danger">Удалить учетную запись</div>
    </div>
  </div>
</template>

<script>
// import axios from "axios";

import axios from "axios";

export default {
  name: "UserActions",
  components: {},
  data() {
    return {
      newEmail: '',
      token: ''
    };
  },
  props: {
    id: Number
  },
  watch: {

  },
  computed: {},
  methods: {
    getToken(){
      this.$store.dispatch("startPreloader");
      let formData = new FormData();
      formData.append("user_id", this.id);
      axios
          .post(`/users/generate-token`, formData)
          .then(resp => {
            this.token = resp.data.token;
            this.$store.dispatch("stopPreloader");
          })
          .catch(err => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
            this.$store.dispatch("stopPreloader");
          });
    },
    editEmail(){
      if(this.newEmail == ''){
        this.$store.dispatch("addNotification", {
          text: 'Поле e-mail пустое',
          time: 4,
          color: "danger"
        });
        return false;
      }

      this.$store.dispatch("startPreloader");
      let formData = new FormData();
      formData.append("user_id", this.id);
      formData.append("email", this.newEmail.trim());
      axios
          .post(`/users/change-email`, formData)
          .then(resp => {
            if(resp.data.result == 'ok'){
              this.$store.dispatch("addNotification", {
                text: 'Успешно',
                time: 4,
                color: "success"
              });
              this.newEmail = '';
            }
            this.$store.dispatch("stopPreloader");
          })
          .catch(err => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
            this.$store.dispatch("stopPreloader");
          });
    },
    deleteUser(){
      this.$store.dispatch("startPreloader");
      let formData = new FormData();
      formData.append("user_id", this.id);
      axios
          .post(`/users/delete-user`, formData)
          .then(resp => {
            if(resp.data.result == 'ok'){
              this.$store.dispatch("addNotification", {
                text: 'Успешно',
                time: 4,
                color: "success"
              });
              this.newEmail = '';
            }
            this.$store.dispatch("stopPreloader");
          })
          .catch(err => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
            this.$store.dispatch("stopPreloader");
          });
    }
  },
  created() {

  }
};
</script>

<style scoped lang="less">

</style>
