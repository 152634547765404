<template>
  <div class="user-roles--container">
    <simple-select
        :data="allRoles"
        :default="defaultRoles"
        @changeValue="changeRolesSelect"
    ></simple-select>
    <div class="input-block mrg-t-10">
      <div @click="saveRoles" class="btn btn-sm btn-success">Сохранить</div>
    </div>
  </div>
</template>

<script>
// import axios from "axios";

import axios from "axios";
import SimpleSelect from "@/components/SimpleSelect";

export default {
  name: "UserRoles",
  components: {SimpleSelect},
  data() {
    return {
      allRoles: [],
      userRoles: [],
      selectedRoles: []
    };
  },
  props: {
    id: Number
  },
  watch: {
    id(){
      this.getRoles();
    }
  },
  computed: {
    defaultRoles(){
      let result = [];
      if(this.userRoles.length){
        for(let key in this.userRoles){
          result[result.length] = this.userRoles[key].id;
        }
      }
      return result;
    }
  },
  methods: {
    saveRoles(){
      this.$store.dispatch("startPreloader");
      let formData = new FormData();
      formData.append("user_id", this.id);
      formData.append("roles", JSON.stringify(this.selectedRoles));
      axios
          .post(`/users/set-roles`, formData)
          .then(resp => {
            if(resp.data.result == 'ok'){
              this.$store.dispatch("addNotification", {
                text: 'Успешно',
                time: 3,
                color: "success"
              });
            }
            this.$store.dispatch("stopPreloader");
          })
          .catch(err => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
            this.$store.dispatch("stopPreloader");
          });
    },
    changeRolesSelect(val) {
      this.selectedRoles = val;
    },
    getRoles(){
      this.$store.dispatch("startPreloader");
      let formData = new FormData();
      formData.append("user_id", this.id);
      axios
          .post(`/users/get-roles`, formData)
          .then(resp => {
            this.allRoles = this.preparePermData(resp.data.all_roles);
            this.userRoles = this.preparePermData(resp.data.user_roles);
            this.$store.dispatch("stopPreloader");
          })
          .catch(err => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
            this.$store.dispatch("stopPreloader");
          });
    },
    preparePermData(val) {
      let result = [];
      for (let key in val) {
        let obj = {};
        obj.id = val[key].name;
        obj.name = val[key].description;
        result.push(obj);
      }
      return result;
    },
  },
  created() {
    this.getRoles();
  }
};
</script>

<style scoped lang="less">

</style>
