<template>
  <panel-main-template>
    <template v-slot:page-title>
      Пользователи
    </template>
    <template v-slot:page-content>
      <div class="users--container">
        <div class="users--search">
          <user-search @userId="showUserInfoPopup"></user-search>
        </div>
        <div class="section-title mrg-t-25">Список пользователей</div>
        <div class="user-list">
          <div class="table--container">
            <div class="table--header">
              <div class="id">ID</div>
              <div class="email">E-mail</div>
              <div class="username">Username</div>
              <div class="register">Register</div>
              <div class="last_login">Last_login</div>
              <div class="confirm_mail">confirm_mail</div>
            </div>
            <div class="table--rows">
              <div @click="showUserInfoPopup(item.id)" v-for="item in data" :key="item.id" class="table--row">
                <div class="id">{{item.id}}</div>
                <div class="email">{{item.email}}</div>
                <div class="username">{{item.username}}</div>
                <div class="register">{{item.date}}</div>
                <div class="last_login">{{item.last_login}}</div>
                <div class="confirm_mail">{{item.confirm_mail}}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="pagginator mrg-t-20">
          <paginator
              :pages="pagesCountPaginator"
              :now="thisPage"
              @setPage="setPage"
          ></paginator>
        </div>


        <popup
            :closeButton="userInfoPopup.closeButton"
            :show="userInfoPopup.show"
            :size="'lg'"
            @closePopup="closeUserInfoPopup"
        >
          <div slot="header">Карточка юзера</div>
          <div slot="body">
            <ul class="tabs-container">
              <li
                  @click="clickTab(item)"
                  v-bind:class="{ active: item.isActive }"
                  v-for="item in tabs"
                  :key="item.id"
              >
                {{ item.name }}
              </li>
            </ul>
            <div class="content">
              <user-info v-if="activeTabIndex === 1" :id="showUserInfoId"></user-info>
              <user-companies v-if="activeTabIndex === 2" :id="showUserInfoId"></user-companies>
              <user-config v-if="activeTabIndex === 3" :id="showUserInfoId"></user-config>
              <user-roles v-if="activeTabIndex === 4" :id="showUserInfoId"></user-roles>
              <user-permissions v-if="activeTabIndex === 5" :id="showUserInfoId"></user-permissions>
              <user-statistics v-if="activeTabIndex === 6" :id="showUserInfoId"></user-statistics>
              <user-actions v-if="activeTabIndex === 7" :id="showUserInfoId"></user-actions>
              <user-support v-if="activeTabIndex === 8" :id="showUserInfoId"></user-support>
              <user-balance v-if="activeTabIndex === 9" :id="showUserInfoId"></user-balance>
              <user-contract v-if="activeTabIndex === 10" :id="showUserInfoId"></user-contract>
              <user-acts v-if="activeTabIndex === 11" :id="showUserInfoId"></user-acts>
              <user-invoices v-if="activeTabIndex === 12" :id="showUserInfoId"></user-invoices>
            </div>
          </div>
        </popup>

      </div>
    </template>
  </panel-main-template>
</template>

<script>
// @ is an alias to /src

import PanelMainTemplate from "../../components/panel/MainTemplate";
import axios from "axios";
import Paginator from "@/components/Paginator";
import Popup from "@/components/Popup";
import UserInfo from "@/components/panel/users/UserInfo";
import UserSearch from "@/components/panel/users/UserSearch";
import UserCompanies from "@/components/panel/users/UserCompanies";
import UserConfig from "@/components/panel/users/UserConfig";
import UserRoles from "@/components/panel/users/UserRoles";
import UserPermissions from "@/components/panel/users/UserPermissions";
import UserStatistics from "@/components/panel/users/UserStatistics";
import UserActions from "@/components/panel/users/UserActions";
import UserSupport from "@/components/panel/users/UserSupport";
import UserBalance from "../../components/panel/users/UserBalance";
import UserContract from "../../components/panel/users/UserContract";
import UserActs from "../../components/panel/users/UserActs";
import UserInvoices from "../../components/panel/users/UserInvoices";

// const permissions = new Permissions();

export default {
  name: "Users",
  components: {
    UserInvoices,
    UserActs,
    UserContract,
    UserBalance,
    UserSupport,
    UserActions,
    UserStatistics,
    UserPermissions,
    UserRoles,
    UserConfig,
    UserCompanies,
    UserSearch,
    UserInfo,
    Popup,
    Paginator,
    PanelMainTemplate
  },
  data() {
    return {
      tabs: [
        {
          id: 1,
          name: "Общая информация",
          isActive: false
        },
        {
          id: 2,
          name: "Компании",
          isActive: false
        },
        {
          id: 3,
          name: "Конфиги",
          isActive: false
        },
        {
          id: 4,
          name: "Роли",
          isActive: false
        },
        {
          id: 5,
          name: "Права",
          isActive: false
        },
        {
          id: 6,
          name: "Статистика",
          isActive: false
        },
        {
          id: 7,
          name: "Доп.действия",
          isActive: false
        },
        {
          id: 8,
          name: "Чат",
          isActive: false
        },
        {
          id: 9,
          name: "Баланс/История",
          isActive: false
        },
        {
          id: 10,
          name: "Contract",
          isActive: false
        },
        {
          id: 11,
          name: "Акты",
          isActive: false
        },
        {
          id: 12,
          name: "Invoices",
          isActive: false
        }
      ],
      userInfoPopup: {
        show: false,
        closeButton: "Отмена"
      },
      showUserInfoId: null,
      data: [],
      thisPage: 1, // Текущая страница
      pagesCountPaginator: 0, // Кол-во страниц
      pageSize: 25, // Сколько записей подгружать на 1 страницу
    };
  },
  computed: {
    activeTabIndex() {
      let result = 0;
      this.tabs.forEach(function(item) {
        if (item.isActive) {
          result = item.id;
        }
      });
      return result;
    }
  },
  methods: {
    clickTab(item) {
      for (let key in this.tabs) {
        if (this.tabs[key].id === item.id) {
          this.tabs[key].isActive = true;
        } else {
          this.tabs[key].isActive = false;
        }
      }
    },
    showUserInfoPopup(userId){
      this.showUserInfoId = +userId;
      this.userInfoPopup.show = true;
    },
    closeUserInfoPopup(){
      this.userInfoPopup.show = false;
    },
    getData() {
      this.$store.dispatch("startPreloader");
      let formData = new FormData();
      formData.append("page", this.thisPage);
      formData.append("page_size", this.pageSize);
      axios
          .post(`/users/get-list`, formData)
          .then(resp => {
            this.data = resp.data.data;
            this.pagesCountPaginator = resp.data.pages;
            this.$store.dispatch("stopPreloader");
          })
          .catch(err => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
            this.$store.dispatch("stopPreloader");
          });
    },
    setPage(val) {
      this.thisPage = val;
      this.getData();
    },
  },
  created() {
    this.getData();
  }
};
</script>

<style scoped lang="less">
.users--search{
  width: 45%;
}
.user-list--main{
  overflow: auto;
}

@media (max-width: 700px) {
  .users--search{
    width: 100%;
  }
}
</style>
