<template>
  <transition name="fade">
    <div v-show="show" class="pop-up" v-on:click.self="closePopup">
      <div
        v-bind:class="{
          pop_up__modal_lg: size == 'lg',
          pop_up__modal_sm: size != 'lg'
        }"
      >
        <div class="pop-up--content">
          <div class="pop-up--modal-header">
            <div class="pop-up--title">
              <slot name="header"></slot>
            </div>
            <div v-on:click="closePopup" class="pop-up--close cursor-pointer">
              <f-awesome icon="times"></f-awesome>
            </div>
          </div>
          <div class="pop-up--modal-body">
            <slot name="body"></slot>
          </div>
          <div class="pop-up--modal-footer">
            <div
              v-on:click="closePopup"
              v-if="closeButtonShow"
              class="btn btn-sm btn-danger"
            >
              {{ closeButton }}
            </div>
            <div
              v-on:click="actionPopup"
              v-if="actionButtonShow"
              class="btn btn-sm"
              v-bind:class="actionClass"
            >
              {{ actionButton }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
/**
 * $emit closePopup
 * $emit actionPopup
 */
export default {
  name: "Popup",
  props: {
    show: Boolean, // true/false - Показать/Скрыть
    closeButton: String, // Название кнопки, которая скрывает окно
    actionButton: String, // Название кнопки какого-либо действия
    actionClass: String, // Класс кнопки действия, например btn-success
    size: String // 'lg' - широкое окно, если не указывать- узкое окно
  },
  watch: {
    show: function(val) {
      if (val === true) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "auto";
      }
    }
  },
  computed: {
    closeButtonShow() {
      return this.closeButton ? true : false;
    },
    actionButtonShow() {
      return this.actionButton ? true : false;
    }
  },
  methods: {
    closePopup() {
      this.$emit("closePopup");
    },
    actionPopup() {
      this.$emit("actionPopup");
    }
  }
};
</script>

<style scoped lang="less">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
}
.pop-up {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  z-index: 19999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
}
.pop_up__modal_sm {
  max-width: 500px;
  background-color: #fff;
  margin: 20px auto 20px auto;
  border-radius: 5px;
  padding: 15px;
  box-sizing: border-box;
  z-index: 19999;
}
.pop_up__modal_lg {
  max-width: 750px;
  background-color: #fff;
  margin: 20px auto 20px auto;
  border-radius: 5px;
  padding: 15px;
  box-sizing: border-box;
  z-index: 19999;
}
.pop-up--modal-header {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  color: #4f4f4f;
}
.pop-up--title {
  font-size: 18px;
  font-weight: 500;
}
.pop-up--modal-body {
  padding: 15px 5px;
  box-sizing: border-box;
  color: #4f4f4f;
  font-size: 14px !important;
  font-weight: 400;
}
.pop-up--modal-footer {
  padding: 10px 5px;
  box-sizing: border-box;
  text-align: right;
}
.pop-up--close{
  font-size: 24px;
}
@media (max-width: 700px) {
  .pop_up__modal_sm {
    padding: 5px;
  }
  .pop_up__modal_lg {
    padding: 5px;
  }
}
</style>
