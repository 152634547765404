<template>
  <div class="user-balance--container">
    <div class="shadow-card">
      Денег: {{balance.money}}<br>
      Баллов: {{balance.points}}<br>
      Записей: {{ balance.enroll_package }}<br>
      СМС: {{ balance.sms_package }}
    </div>
    <div class="user-balance--create-operation mrg-t-15">
      <div class="shadow-card">
        <div class="text-bold">Создать операцию</div>
        <div class="input-block mrg-t-15">
          <label for="purpose">На какой счет <span class="req">*</span></label>
          <select v-model="createOperation.purpose" id="purpose">
            <option value="money">Лицевой счет</option>
            <option value="points">Бонусный счет</option>
            <option value="enroll">Пакеты записей</option>
            <option value="sms">Пакеты СМС</option>
          </select>
        </div>
        <div class="input-block">
          <label for="operator">Оператор <span class="req">*</span></label>
          <select v-model="createOperation.operator" id="operator">
            <option value="plus">Пополнить</option>
            <option value="minus">Списать</option>
          </select>
        </div>
        <div class="input-block">
          <label for="value">Сумма <span class="req">*</span></label>
          <input v-model="createOperation.value" id="value">
        </div>
        <div class="input-block">
          <label for="operation_type">Тип операции <span class="req">*</span></label>
          <select v-model="createOperation.operation_type" id="operation_type">
            <option value="USER_TOP">Пополнение пользователем</option>
            <option value="REFUND">Возврат средств</option>
            <option value="GIFT">Подарок</option>
          </select>
        </div>
        <div class="input-block">
          <label for="need_act">Нужен АКТ <span class="req">*</span></label>
          <input v-model="createOperation.need_act" id="need_act">
        </div>
        <div class="input-block">
          <label for="datetime">Дата и время (необязательно)</label>
          <input v-model="createOperation.datetime" id="datetime">
        </div>
        <div class="input-block">
          <label for="invoice_id">ID выставленного платежа. Если не нужно, то оставляем 0</label>
          <input v-model="createOperation.invoice_id" id="invoice_id">
        </div>
        <div class="input-block">
          <div @dblclick="setOperation" class="btn btn-sm btn-success">Добавить</div>
        </div>
      </div>
    </div>
    <div class="shadow-card mrg-t-15">
      <div class="text-bold">Автопополнение по выставленному платежу</div>
      <div class="input-block mrg-t-15">
        <div class="input-block">
          <label for="invoice_id">ID выставленного платежа</label>
          <input v-model="autoTop.invoice_id" id="autotop_invoice_id">
        </div>
        <div class="input-block">
          <div @dblclick="setAutoTop" class="btn btn-sm btn-success">Пополнить</div>
        </div>
      </div>
    </div>
    <div class="section-title mrg-t-15">Последние 200 операций по балансу</div>
    <div class="payment--history-data">
      <div v-if="history.length" class="payment--history-data-table">
        <div class="table--container">
          <div class="table--header">
            <div>BO_ID</div>
            <div>Счет операции</div>
            <div>Детали операции</div>
            <div>Сумма</div>
            <div>Дата, время (согласно настройке часового пояса компании) </div>
          </div>
          <div class="table--rows">
            <div class="table--row" v-for="item in history" :key="item.id">
              <div>{{item.id}}</div>
              <div>{{item.purpose}}</div>
              <div>{{item.operation_type}}</div>
              <div v-bind:class="{'text-success text-bold': item.operation_value > 0}">{{item.operation_value}}</div>
              <div>{{item.operation_datetime}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "UserBalance",
  components: {},
  data() {
    return {
      balance: {money: '', points: ''},
      history: [],
      autoTop: {
        invoice_id: ''
      },
      createOperation: {
        purpose: 'money',
        operator: 'plus',
        value: '',
        operation_type: 'USER_TOP',
        need_act: 0,
        datetime: '',
        invoice_id: 0
      }
    };
  },
  props: {
    id: Number
  },
  watch: {
    id(){
      this.getInfo();
    }
  },
  computed: {},
  methods: {
    setAutoTop(){
      this.$store.dispatch("startPreloader");
      let formData = new FormData();
      formData.append("id", this.autoTop.invoice_id);

      axios
          .post(`/users/auto-top-by-invoice`, formData)
          .then(() => {
            this.getInfo();
            this.autoTop.invoice_id = 0;
            this.$store.dispatch("stopPreloader");
          })
          .catch(err => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
            this.$store.dispatch("stopPreloader");
          });
    },
    getInfo(){
      this.$store.dispatch("startPreloader");
      let formData = new FormData();
      formData.append("id", this.id);
      axios
          .post(`/users/get-balance-and-history`, formData)
          .then(resp => {
            this.balance = resp.data.balance;
            this.history = resp.data.history;
            this.$store.dispatch("stopPreloader");
          })
          .catch(err => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
            this.$store.dispatch("stopPreloader");
          });
    },
    setOperation(){
      this.$store.dispatch("startPreloader");
      let formData = new FormData();
      for(let key in this.createOperation){
        formData.append(key, this.createOperation[key]);
      }
      formData.append("user_id", this.id);
      axios
          .post(`/users/set-balance-operation`, formData)
          .then(() => {
            this.getInfo();
            this.$store.dispatch("stopPreloader");
          })
          .catch(err => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
            this.$store.dispatch("stopPreloader");
          });
    }
  },
  created() {
    this.getInfo();
  }
};
</script>

<style scoped lang="less">

</style>
