<template>
  <div class="home">
    <main-header></main-header>

    <div class="home--container">
      <div class="section-title mrg-t-25">Личный кабинет</div>

      <div v-if="userData" class="account-card">
        <div class="email">{{userData[0].email}}</div>
        <div class="actions">
          <router-link tag="a" class="btn btn-sm btn-info" :to="`/server/`"
          ><f-awesome icon="sign-in-alt"></f-awesome> В журнал записей</router-link>
          <div @click="logout" class="btn btn-sm btn-orange">Выйти</div>
        </div>
      </div>

      <div v-if="!userData" class="buttons mrg-t-25">
        <router-link tag="a" class="btn btn-sm btn-info" :to="`/login/`"
        ><f-awesome icon="sign-in-alt"></f-awesome> Войти</router-link>
      </div>
    </div>

    <main-footer></main-footer>
  </div>
</template>

<script>
// @ is an alias to /src

import MainHeader from "@/components/MainHeader";
import MainFooter from "@/components/MainFooter";
import { mapState } from "vuex";

export default {
  name: "Home",
  components: {MainFooter, MainHeader},
  computed: {
    ...mapState({
      userData: state => state.authorization.userData
    })
  },
  methods: {
    logout() {
      this.$store.dispatch("logout").then(() => {
        console.log("logout");
        this.$router.push("/");
      });
    }
  },
  created() {

  },
};
</script>

<style scoped lang="less">
.home--container {
  width: 800px;
  margin: 0 auto 60px auto;
  text-align: center;
}
.account-card{
  width: 320px;
  border-radius: 8px;
  padding: 10px 6px;
  box-sizing: border-box;
  box-shadow: 0px 5px 9px 0px rgba(0, 0, 0, 0.25);
  margin: 30px auto 0 auto;

  .actions{
    margin-top: 5px;
  }
}
@media (max-width: 1200px) {
  .home--container {
    width: 100%;
  }
}
</style>
