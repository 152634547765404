<template>
  <div class="user-invoices--container">
    <div class="user-invoices--history mrg-t-15">
      <div class="section-title">История выставленных платежей (последние 200)</div>
      <div class="table--container">
        <div class="table--header">
          <div>Номер</div>
          <div>Сумма</div>
          <div>Дата</div>
          <div>BO_ID</div>
          <div>Скачать</div>
        </div>
        <div class="table--rows">
          <div class="table--row" v-for="item in data" :key="item.id">
            <div>{{item.id}}</div>
            <div>{{item.sum}}</div>
            <div>{{item.created}}</div>
            <div>
              <div v-if="item.positions.length > 0">
                <div v-for="pos in item.positions" :key="pos.id">{{ pos.bo_id }}</div>
              </div>
              <div v-if="item.positions.length === 0">{{item.bo_id}}</div>
            </div>
            <div>
              <a
                  :href="item.download_link"
                  class="btn btn-sm btn-success"
                  target="_blank"
              >
                <f-awesome icon="download"></f-awesome>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "UserInvoices",
  components: {},
  data() {
    return {
      data: []
    };
  },
  props: {
    id: Number
  },
  watch: {
    id(){
      this.getInfo();
    }
  },
  computed: {},
  methods: {
    getInfo(){
      this.$store.dispatch("startPreloader");
      let formData = new FormData();
      formData.append("id", this.id);
      axios
          .post(`/users/get-invoices`, formData)
          .then(resp => {
            this.data = resp.data;
            this.$store.dispatch("stopPreloader");
          })
          .catch(err => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
            this.$store.dispatch("stopPreloader");
          });
    }
  },
  created() {
    this.getInfo();
  }
};
</script>

<style scoped lang="less">

</style>
