class Formatter {
  /**
   * Возвращает date object
   * @param day
   * @param month - в формате js (0 - январь, 1 - февраль)
   * @param year
   * @returns {Date}
   */
  paramsDateToJSObject(day, month, year) {
    return new Date(year, month, day);
  }

  datetimeToRus(datetime){
    let dateRus = this.phpDateToRus(datetime);
    let time = datetime.split(' ')[1];
    return dateRus +' ' +time;
  }


  /**
   * Переводит объект JS в формат PHP
   * @param obj
   * @returns {string}
   */
  jsObjectToPhp(obj) {
    return this.paramsDateToPhpFormat(
      obj.getDate(),
      obj.getMonth(),
      obj.getFullYear()
    );
  }

  /**
   * Переводит дату из YYYY-MM-DD в объект JS.
   * Почему такой способ? Потому что new Date('yyyy-dd-mm') вхерачивает часовой пояс
   * @param date
   * @returns {Date}
   */
  phpDateToJSObject(date) {
    let newDate = new Date(date);
    return this.paramsDateToJSObject(
      newDate.getDate(),
      newDate.getMonth(),
      newDate.getFullYear()
    );
  }

  /**
   * Форматирует параметры даты в строку php
   * @param day
   * @param month - в формате js (0 - январь, 1 - февраль)
   * @param year
   * @returns {string}
   */
  paramsDateToPhpFormat(day, month, year) {
    let getObj = this.paramsDateToJSObject(day, month, year);
    return this.dateToPhpFormat(getObj);
  }

  /**
   * Форматирует дату из объекта js в строку php
   * @param date object dateObj
   * @returns {string} 'YYYY-MM-DD'
   */
  dateToPhpFormat(dateObj) {
    let day = dateObj.getDate();
    let month = dateObj.getMonth() + 1;
    let year = dateObj.getFullYear();
    return (
      year +
      "-" +
      (month < 10 ? "0" + month : month) +
      "-" +
      (day < 10 ? "0" + day : day)
    );
  }

  /**
   * Форматирует дату вида '2020-01-01' в '01 января 2020'
   * @param string date - YYYY-MM-DD
   * @returns {string} '01 января 2020'
   */
  phpDateToString(date) {
    let month = [
      "января",
      "февраля",
      "марта",
      "апреля",
      "мая",
      "июня",
      "июля",
      "августа",
      "сентября",
      "октября",
      "ноября",
      "декабря"
    ];
    let dateObj = new Date(date);
    let day =
      dateObj.getDate() < 10 ? "0" + dateObj.getDate() : dateObj.getDate();
    return day + " " + month[dateObj.getMonth()] + " " + dateObj.getFullYear();
  }

  /**
   * Форматирует дату из формата dd.mm.yyyy в объект Date JS
   * @param string date - dd.mm.yyyy
   * @returns {Date}
   */
  rusDateToJSObject(date) {
    let dateSplit = date.split(".");
    return this.paramsDateToJSObject(
      +dateSplit[0],
      +dateSplit[1] - 1,
      +dateSplit[2]
    );
  }

  /**
   * Переводит параметры даты в формат dd.mm.yyyy
   * @param day
   * @param month
   * @param year
   * @returns {string}
   */
  dateParamsToRus(day, month, year) {
    let resDay = +day < 10 ? "0" + day : day;
    let resMonth = +month < 10 ? "0" + month : month;
    let resYear = year;
    return resDay + "." + resMonth + "." + resYear;
  }

  /**
   * Переводит дату из формата YYYY-MM-DD в dd.mm.yyyy
   * @param date
   * @returns {string}
   */
  phpDateToRus(date) {
    let dateParams = this.phpDateToJSObject(date);
    return this.dateParamsToRus(
      dateParams.getDate(),
      dateParams.getMonth() + 1,
      dateParams.getFullYear()
    );
  }

  /**
   * Переводит минуты в часы
   * @param integer minute
   * @returns {string}
   */
  minuteToTime(minute) {
    let hour = Math.floor(minute / 60);
    let minutes = minute % 60 < 10 ? "0" + (minute % 60) : minute % 60;

    return hour + ":" + minutes;
  }

  /**
   * Разбивает минуты на 2 параметра hour, minute
   * @param minutes
   * @returns {{}}
   */
  minuteSplitHourMinute(minutes) {
    let result = {};
    result.hour = Math.floor(minutes / 60);
    result.minute = minutes - Math.floor(minutes / 60) * 60;
    return result;
  }

  /**
   * Минуты в человекопонятное время вида: 1 ч. 20 мин.
   * @param minutes
   * @returns {string}
   */
  minuteSplitHumanHourMinute(minutes) {
    let time = this.minuteSplitHourMinute(minutes);
    if (time.hour === 0) {
      return time.minute + " мин.";
    } else {
      return time.hour + " ч. " + time.minute + " мин.";
    }
  }

  /**
   * Переводит часы и минуты в минуты
   * @param hour
   * @param minute
   * @returns {string}
   */
  hourMinuteToMinute(hour, minute) {
    return +hour * 60 + +minute;
  }

  generateHash(length){
    let chars = '12345AaBbCcDdEeFfGgHhJjKkLMmNnPpQRrSsTtUuVvWwXxYyZz6789';
    let hash = '';
    for(let z = 0; z <= 100; z++){
      for (let i = 0; i < length; i++){
        let randKey = Math.floor(Math.random() * ((chars.length -1) - 0 + 1) ) + 0;
        hash += chars[randKey];
      }
      break;
    }

    return hash;
  }

}

export default Formatter;
