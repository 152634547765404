<template>
  <div class="user-contract--container">
    <div class="shadow-card mrg-t-15">

      <div class="input-block">
        <label for="type">Тип аккаунта <span class="req">*</span></label>
        <select v-model="data.type" id="type">
          <option value="legal">Юридическое лицо</option>
          <option value="ip">Индивидуальный предприниматель</option>
        </select>
        <div class="form-error"></div>
      </div>

      <div class="input-block" v-if="data.type == 'legal'">
        <label for="organization_name">Наименование организации <span class="req">*</span></label>
        <input
            type="text"
            v-model="data.organization_name"
            id="organization_name"
        />
      </div>

      <div class="input-block" v-if="data.type == 'ip'">
        <label for="ip_name">Наименование ИП <span class="req">*</span></label>
        <input
            type="text"
            v-model="data.ip_name"
            id="ip_name"
        />
        <div class="hint">Например: ИП Морозов Павел Сергеевич</div>
      </div>

      <div class="input-block">
        <label for="legal_address">Юридический адрес <span class="req">*</span></label>
        <input
            type="text"
            v-model="data.legal_address"
            id="legal_address"
        />
        <div class="hint">Полный. Например: г.Москва, ул. Ленина, д.1 ...</div>
      </div>

      <div class="input-block">
        <label for="inn">ИНН <span class="req">*</span></label>
        <input
            type="text"
            v-model="data.inn"
            id="inn"
        />
      </div>

      <div class="input-block" v-if="data.type == 'legal'">
        <label for="kpp">КПП <span class="req">*</span></label>
        <input
            type="text"
            v-model="data.kpp"
            id="kpp"
        />
      </div>

      <div class="input-block">
        <label for="ogrn_ogrnip">ОГРН / ОГРНИП <span class="req">*</span></label>
        <input
            type="text"
            v-model="data.ogrn_ogrnip"
            id="ogrn_ogrnip"
        />
      </div>

      <div class="input-block" v-if="data.type == 'legal'">
        <label for="fio_contract_person">ФИО лица заключающего договор <span class="req">*</span></label>
        <input
            type="text"
            v-model="data.fio_contract_person"
            id="fio_contract_person"
        />
      </div>

      <div class="input-block" v-if="data.type == 'legal'">
        <label for="profession_contract_person">Должность лица заключающего договор <span class="req">*</span></label>
        <input
            type="text"
            v-model="data.profession_contract_person"
            id="profession_contract_person"
        />
      </div>

      <div class="input-block" v-if="data.type == 'legal'">
        <label for="organization_phone">Номер телефона организации <span class="req">*</span></label>
        <input
            type="text"
            v-model="data.organization_phone"
            id="organization_phone"
        />
      </div>

      <div class="input-block">
        <label for="post_index">Почтовый индекс <span class="req">*</span></label>
        <input
            type="text"
            v-model="data.post_index"
            id="post_index"
        />
      </div>

      <div class="input-block">
        <label for="post_address">Почтовый адрес <span class="req">*</span></label>
        <input
            type="text"
            v-model="data.post_address"
            id="post_address"
        />
      </div>

      <div class="input-block" v-if="data.type == 'legal'">
        <label for="bookkeeper_email">E-mail бухгалтера <span class="req">*</span></label>
        <input
            type="text"
            v-model="data.bookkeeper_email"
            id="bookkeeper_email"
        />
      </div>

      <div class="input-block">
        <label for="payment_account_bank">Расчетный счет банка <span class="req">*</span></label>
        <input
            type="text"
            v-model="data.payment_account_bank"
            id="payment_account_bank"
        />
      </div>

      <div class="input-block">
        <label for="payment_name_bank">Наименование банка <span class="req">*</span></label>
        <input
            type="text"
            v-model="data.payment_name_bank"
            id="payment_name_bank"
        />
      </div>

      <div class="input-block">
        <label for="payment_correspondent_account">Корреспондентский счет <span class="req">*</span></label>
        <input
            type="text"
            v-model="data.payment_correspondent_account"
            id="payment_correspondent_account"
        />
      </div>

      <div class="input-block">
        <label for="payment_bik_bank">БИК банка <span class="req">*</span></label>
        <input
            type="text"
            v-model="data.payment_bik_bank"
            id="payment_bik_bank"
        />
      </div>

      <div class="input-block" v-if="data.type == 'legal'">
        <label for="fio_contact_person">ФИО контактного лица <span class="req">*</span></label>
        <input
            type="text"
            v-model="data.fio_contact_person"
            id="fio_contact_person"
        />
      </div>

      <div class="input-block" v-if="data.type == 'legal'">
        <label for="phone_number_contact_person">Номер телефона контактного лица <span class="req">*</span></label>
        <input
            type="text"
            v-model="data.phone_number_contact_person"
            id="phone_number_contact_person"
        />
      </div>

      <div class="input-block">
        <label for="datetime">Дата подписания (# {{data.id}}) <span class="req">*</span></label>
        <input
            type="text"
            v-model="data.datetime"
            id="datetime"
        />
      </div>

      <div class="input-block">
        <div @dblclick="setData('update')" class="btn btn-sm btn-success">Обновить</div>
        <div @dblclick="setData('create')" class="btn btn-sm btn-success">Создать новый</div>
      </div>

    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "UserContract",
  components: {},
  data() {
    return {
      data: {
        id: '',
        type: 'legal',
        organization_name: '',
        inn: '',
        legal_address: '',
        kpp: '',
        ogrn_ogrnip: '',
        fio_contract_person: '',
        profession_contract_person: '',
        organization_phone: '',
        post_index: '',
        post_address: '',
        bookkeeper_email: '',
        payment_account_bank: '',
        payment_name_bank: '',
        payment_correspondent_account: '',
        payment_bik_bank: '',
        fio_contact_person: '',
        phone_number_contact_person: '',
        ip_name: '',
        datetime: ''
      },
      canEdit: 0
    };
  },
  props: {
    id: Number
  },
  watch: {
    id(){
      this.getInfo();
    }
  },
  computed: {},
  methods: {
    getInfo(){
      this.$store.dispatch("startPreloader");
      let formData = new FormData();
      formData.append("id", this.id);
      axios
          .post(`/users/get-contract`, formData)
          .then(resp => {
            this.setParams(resp.data.data);
            this.canEdit = resp.data.can_edit;
            this.$store.dispatch("stopPreloader");
          })
          .catch(err => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
            this.$store.dispatch("stopPreloader");
          });
    },
    setData(trigger){
      this.$store.dispatch("startPreloader");
      let formData = new FormData();
      for(let key in this.data){
        if(trigger === 'create' && (key == 'id' || key == 'datetime')){
          continue;
        }
        formData.append(key, this.data[key]);
      }

      formData.append("user_id", this.id);
      formData.append("trigger", trigger);
      axios
          .post(`/users/set-contract`, formData)
          .then(resp => {
            this.setParams(resp.data.data);
            this.canEdit = resp.data.can_edit;
            this.$store.dispatch("stopPreloader");
          })
          .catch(err => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
            this.$store.dispatch("stopPreloader");
          });
    },
    setParams(params){
      for(let keyD in this.data){
        let existsParam = false;
        for(let key in params){
          if(key == keyD){
            existsParam = true;
            this.data[keyD] = params[key];
          }
        }
        if(!existsParam){
          this.data[keyD] = '';
        }
      }
    }
  },
  created() {
    this.getInfo();
  }
};
</script>

<style scoped lang="less">

</style>
