<template>
  <div v-if="isShow" class="preloader">
    <img :src="require('../assets/img/loader.gif')" />
  </div>
</template>

<script>

export default {
  name: "SimplePreloader",
  props: {
    isShow: Boolean
  },
  methods: {}
};
</script>

<style scoped lang="less">
.preloader {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 20000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;

  & > img,
  div {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 30px;
  }
}
</style>
