<template>
  <div class="user-info--container">
    <div class="this-user">
      <div class="section-title">Инфо юзера</div>

      <div class="mrg-t-10"><b>id:</b> {{data.user.id}}</div>
      <div><b>e-mail:</b> {{data.user.email}}</div>
      <div><b>username:</b> {{data.user.username}}</div>
      <div><b>register:</b> {{data.user.date}}</div>
      <div><b>last_login:</b> {{data.user.last_login}}</div>
      <div><b>confirm_mail:</b> {{data.user.confirm_mail}}</div>
      <div><b>server:</b> {{data.user.server}}</div>
    </div>
    <div v-if="data.parent_user.id" class="parent-user mrg-t-20">
      <div class="section-title">Родительский аккаунт</div>

      <div class="mrg-t-10"><b>id:</b> {{data.parent_user.id}}</div>
      <div><b>e-mail:</b> {{data.parent_user.email}}</div>
      <div><b>username:</b> {{data.parent_user.username}}</div>
      <div><b>register:</b> {{data.parent_user.date}}</div>
      <div><b>last_login:</b> {{data.parent_user.last_login}}</div>
      <div><b>confirm_mail:</b> {{data.parent_user.confirm_mail}}</div>
    </div>
    <div v-if="data.child_users" class="child_users mrg-t-20">
      <div class="section-title">Дочерние аккаунты</div>

      <div v-for="item in data.child_users" :key="item.id" class="item mrg-t-10">
        <div><b>id:</b> {{item.id}}</div>
        <div><b>e-mail:</b> {{item.email}}</div>
        <div><b>username:</b> {{item.username}}</div>
        <div><b>register:</b> {{item.date}}</div>
        <div><b>last_login:</b> {{item.last_login}}</div>
        <div><b>confirm_mail:</b> {{item.confirm_mail}}</div>
        <div><b>server:</b> {{item.server}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "UserInfo",
  components: {},
  data() {
    return {
      data: {
        user: {},
        parent_user: {},
        child_users: []
      }
    };
  },
  props: {
    id: Number
  },
  watch: {
    id(){
      this.getInfo();
    }
  },
  computed: {},
  methods: {
    getInfo(){
      this.$store.dispatch("startPreloader");
      let formData = new FormData();
      formData.append("id", this.id);
      axios
          .post(`/users/get-info-one`, formData)
          .then(resp => {
            this.data = resp.data;
            this.$store.dispatch("stopPreloader");
          })
          .catch(err => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
            this.$store.dispatch("stopPreloader");
          });
    }
  },
  created() {
    this.getInfo();
  }
};
</script>

<style scoped lang="less">

</style>
