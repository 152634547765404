import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import axios from "axios";
import config from "@/config";
import wysiwyg from "vue-wysiwyg";
import Formatter from "./components/libraries/Formatter";

const formatter = new Formatter();

Vue.use(wysiwyg, {
    hideModules: {
        "link": true,
        "headings": true,
        "code": true,
        "image": true,
        "table": true
    }
});

Vue.config.productionTip = false

// FONT AWESOME
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(fas);
Vue.component("f-awesome", FontAwesomeIcon);

//========= AXIOS
Vue.prototype.$http = axios;

Vue.prototype.$http.defaults.baseURL = config.rest_point;
// Перехватываем протухший token
Vue.prototype.$http.interceptors.response.use(undefined, error => {
  let res = error.response;

  if (res.status === 401 && res.config && !res.config.__isRetryRequest) {
    return new Promise((resolve, reject) => {
      let userData = JSON.parse(localStorage.getItem("userData"));
      let formData = new FormData();
      formData.append("refresh_token", userData[0].refresh_token);
      formData.append("token", userData[0].token);

      axios
          .post(`/auth/refresh`, formData)
          .then(resp => {
            const tokenT = resp.data.token;
            const refresh_token = resp.data.refresh_token;
            const perms = resp.data.permissions;
            let userData = [{}];
            let getThisData = JSON.parse(localStorage.getItem("userData"));
            userData[0].token = tokenT;
            userData[0].refresh_token = refresh_token;
            userData[0].email = getThisData[0].email;
            localStorage.setItem("userData", JSON.stringify(userData));
            localStorage.setItem("permissions", JSON.stringify(perms));
            Vue.prototype.$http.defaults.headers.common[
                "Authorization"
                ] = `Bearer ${tokenT}`;
            error.config.headers.Authorization = `Bearer ${tokenT}`;
            error.config.__isRetryRequest = true;
            // axios(error.config);
            resolve(axios(error.config));
          })
          .catch(err => {
            store.dispatch("logout").then(() => {
              router.push("/");
            });
            // commit("auth_error", err.response.message);
            reject(err);
          });
    });
  }
  return Promise.reject(error);
});

const userDataStorage = JSON.parse(localStorage.getItem("userData"));
let token = false;
if (userDataStorage) {
  token = userDataStorage[0].token;
}

const browserIdStorage = localStorage.getItem('enroller_browser_id');
let enrollerBrowserId = false;
if(browserIdStorage){
    enrollerBrowserId = browserIdStorage;
}else{
    let newBrowserId = formatter.generateHash(20);
    localStorage.setItem('enroller_browser_id', newBrowserId);
    enrollerBrowserId = newBrowserId;
}

if (token) {
    Vue.prototype.$http.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    Vue.prototype.$http.defaults.headers.common["EnrollerBrowserId"] = enrollerBrowserId;
}
//========= END AXIOS

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
