<template>
  <panel-main-template>
    <template v-slot:page-title>
      Справочники
    </template>
    <template v-slot:page-content>
      <div class="dicts--container">
        <div class="left">
          <div class="section-title">Сгруппировать справочники</div>
          <div class="group--new mrg-t-10">
            <div class="input-block">
              <label>Родительский справочник</label>
              <select v-model="newGroup.mainDict">
                <option v-for="item in dicts" :key="item.id" :value="item.id">{{item.code_name}} ({{item.name}})</option>
              </select>
            </div>
            <div class="input-block">
              <label>Дочерний справочник</label>
              <select v-model="newGroup.secondDict">
                <option v-for="item in dicts" :key="item.id" :value="item.id">{{item.code_name}} ({{item.name}})</option>
              </select>
            </div>
            <div class="input-block">
              <div @click="groupDictsSubmit" class="btn btn-sm btn-success">Сгруппировать</div>
            </div>
          </div>
          <div class="section-title mrg-t-20">Сгруппировать значения</div>
          <div class="group-values mrg-t-10">
            <div class="group-values--main">
              <div class="input-block">
                <label>Родительский справочник</label>
                <select v-model="groupValues.mainDict">
                  <option v-for="item in dicts" :key="item.id" :value="item.id">
                    {{item.code_name}} ({{item.name}})
                  </option>
                </select>
              </div>
              <div class="input-block">
                <label>Значение</label>
                <select v-model="groupValues.mainValue">
                  <option v-for="item in groupValuesMainVal" :key="item.id" :value="item.id">{{item.value}} ({{item.description}})</option>
                </select>
              </div>
            </div>
            <div class="group-values--main">
              <div class="input-block">
                <label>Дочерний справочник</label>
                <select v-model="groupValues.secondDict">
                  <option v-for="item in dicts" :key="item.id" :value="item.id">
                    {{item.code_name}} ({{item.name}})
                  </option>
                </select>
              </div>
              <div class="input-block">
                <label>Значение</label>
                <select v-model="groupValues.secondValue">
                  <option v-for="item in groupValuesSecondVal" :key="item.id" :value="item.id">{{item.value}} ({{item.description}})</option>
                </select>
              </div>
            </div>
            <div class="input-block">
              <div @click="groupValuesSubmit" class="btn btn-sm btn-success">Сгруппировать</div>
            </div>
          </div>
          <div class="section-title mrg-t-25">Групповые справочники</div>
          <div class="group-dicts--container">
            <div class="group-item" v-for="item in groupDicts" :key="item.id">
              <div class="group-name">
                <div>{{item.code_name}} <span class="description">({{item.name}})</span></div>
                <div>
                  <div
                      class="slide-circle-white"
                      @click="item.show_dir_group = !item.show_dir_group"
                  >
                    <f-awesome
                        v-show="item.show_dir_group"
                        icon="angle-up"
                    ></f-awesome>
                    <f-awesome
                        v-show="!item.show_dir_group"
                        icon="angle-down"
                    ></f-awesome>
                  </div>
                </div>
              </div>
              <div v-show="item.show_dir_group" class="group-second" v-for="group in item.directoryGroups" :key="group.id">
                <div class="group-name--second">
                  <div>{{group.dirSecond.code_name}} ({{group.dirSecond.name}})</div>
                  <div>
                    <div
                        class="slide-circle-white"
                        @click="group.show_group_val = !group.show_group_val"
                    >
                      <f-awesome
                          v-show="item.show_values"
                          icon="angle-up"
                      ></f-awesome>
                      <f-awesome
                          v-show="!item.show_values"
                          icon="angle-down"
                      ></f-awesome>
                    </div>
                  </div>
                  <div>
                    <div
                        @dblclick="deleteGroup(group.id)"
                        class="btn btn-sm btn-danger"
                    >
                      Удалить
                    </div>
                  </div>
                </div>
                <div v-show="group.show_group_val" class="group-values">
                  <div v-for="groupVal in group.directoryGroupValues" :key="groupVal.group_id +'_' +groupVal.main_val_id" class="group-val-item">
                    <div>{{groupVal.mainVal.value}} ({{groupVal.mainVal.description}})</div>
                    <div>=></div>
                    <div>{{groupVal.secondVal.value}} ({{groupVal.secondVal.description}})</div>
                    <div>
                      <div
                          @dblclick="deleteGroupValue(groupVal.group_id, groupVal.mainVal.id, groupVal.secondVal.id)"
                          class="btn btn-sm btn-danger"
                      >
                        <f-awesome icon="times"></f-awesome>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="right">
          <div class="section-title">Создать новый справочник</div>
          <div class="simple-dicts--new mrg-t-10">
            <div class="input-block">
              <label>Кодовое название справочника</label>
              <input type="text" v-model="newDict.value">
            </div>
            <div class="input-block">
              <label>Описание справочника</label>
              <textarea rows="6" v-model="newDict.description"></textarea>
            </div>
            <div class="input-block">
              <div @click="addDict" class="btn btn-sm btn-success">Добавить</div>
            </div>
          </div>
          <div class="simple-dicts--container mrg-t-25">
            <div class="section-title">Справочники</div>
            <div v-for="item in dicts" :key="item.id" class="item">
              <div class="name-flex">
                <div class="name">
                  {{item.code_name}}
                  <span class="description">({{item.name}})</span>
                  <div @dblclick="deleteDict(item.id)" class="btn btn-sm btn-danger">Удалить</div>
                </div>
                <div class="show-values">
                  <div
                      class="slide-circle-white"
                      @click="slideValues(item.id)"
                  >
                    <f-awesome
                        v-show="item.show_values"
                        icon="angle-up"
                    ></f-awesome>
                    <f-awesome
                        v-show="!item.show_values"
                        icon="angle-down"
                    ></f-awesome>
                  </div>
                </div>
              </div>
              <div v-show="item.show_values" class="values">
                <div class="val" v-for="val in item.directoryValues" :key="val.id">
                  <div class="val-flex">
                    <div class="val-val">
                      <div class="input-block">
                        <label>Значение</label>
                        <textarea rows="3" type="text" v-model="val.value"></textarea>
                      </div>
                    </div>
                    <div class="val-descr">
                      <div class="input-block">
                        <label>Описание</label>
                        <textarea rows="6" type="text" v-model="val.description"></textarea>
                      </div>
                    </div>
                  </div>
                  <div class="input-block">
                    <div @dblclick="updateValue(val.id, val.value, val.description)" class="btn btn-sm btn-success">Сохранить</div>
                    <div @dblclick="deleteValue(val.id)" class="btn btn-sm btn-danger">Удалить</div>
                  </div>
                </div>
                <div class="val">
                  <div class="val-flex">
                    <div class="val-val">
                      <div class="input-block">
                        <label>Значение</label>
                        <textarea rows="3" type="text" v-model="newDictVal.value"></textarea>
                      </div>
                    </div>
                    <div class="val-descr">
                      <div class="input-block">
                        <label>Описание</label>
                        <textarea rows="6" type="text" v-model="newDictVal.description"></textarea>
                      </div>
                    </div>
                  </div>
                  <div class="input-block">
                    <div @dblclick="createValue(item.id)" class="btn btn-sm btn-success">Добавить</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </panel-main-template>
</template>

<script>
// @ is an alias to /src

import PanelMainTemplate from "../../components/panel/MainTemplate";
import axios from "axios";
// import axios from "axios";
// import axios from "axios";

// const permissions = new Permissions();

export default {
  name: "Dicts",
  components: {
    PanelMainTemplate
  },
  data() {
    return {
      dicts: [],
      groupDicts: [],
      groupValues: {
        mainDict: '',
        mainValue: '',
        secondDict: '',
        secondValue: ''
      },
      newGroup: {
        mainDict: '',
        secondDict: ''
      },
      newDict: {
        value: '',
        description: ''
      },
      newDictVal: {
        value: '',
        description: ''
      },
      editDictId: null
    };
  },
  computed: {
    groupValuesMainVal(){
      let result = [];
      if(this.groupValues.mainDict > 0){
        for(let key in this.dicts){
          if(+this.dicts[key].id === +this.groupValues.mainDict){
            for(let val in this.dicts[key].directoryValues){
              result.push(this.dicts[key].directoryValues[val]);
            }
          }
        }
      }
      return result;
    },
    groupValuesSecondVal(){
      let result = [];
      if(this.groupValues.secondDict > 0){
        for(let key in this.dicts){
          if(+this.dicts[key].id === +this.groupValues.secondDict){
            for(let val in this.dicts[key].directoryValues){
              result.push(this.dicts[key].directoryValues[val]);
            }
          }
        }
      }
      return result;
    }
  },
  methods: {
    deleteGroup(groupId){
      this.$store.dispatch("startPreloader");
      let formData = new FormData();
      formData.append("group_id", groupId);
      axios
          .post(`/directory/delete-group-dict`, formData)
          .then(resp => {
            if(resp.data.result == 'ok'){
              this.getAll();
            }
            this.$store.dispatch("stopPreloader");
          })
          .catch(err => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
            this.$store.dispatch("stopPreloader");
          });
    },
    groupDictsSubmit(){
      this.$store.dispatch("startPreloader");
      let formData = new FormData();
      formData.append("main_id", this.newGroup.mainDict);
      formData.append("second_dict", this.newGroup.secondDict);
      axios
          .post(`/directory/group-dicts`, formData)
          .then(resp => {
            if(resp.data.result == 'ok'){
              this.newGroup.mainDict = '';
              this.newGroup.secondDict = '';
              this.getAll();
            }
            this.$store.dispatch("stopPreloader");
          })
          .catch(err => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
            this.$store.dispatch("stopPreloader");
          });
    },
    groupValuesSubmit(){
      this.$store.dispatch("startPreloader");
      let formData = new FormData();
      formData.append("main_dict_id", this.groupValues.mainDict);
      formData.append("main_dict_value", this.groupValues.mainValue);
      formData.append("second_dict_id", this.groupValues.secondDict);
      formData.append("second_dict_value", this.groupValues.secondValue);
      axios
          .post(`/directory/group-values`, formData)
          .then(resp => {
            if(resp.data.result == 'ok'){
              this.getAll();
              this.groupValues.mainDict = '';
              this.groupValues.mainValue = '';
              this.groupValues.secondDict = '';
              this.groupValues.secondValue = '';
            }
            this.$store.dispatch("stopPreloader");
          })
          .catch(err => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
            this.$store.dispatch("stopPreloader");
          });
    },
    deleteGroupValue(groupId, mainValId, secondValId){
      this.$store.dispatch("startPreloader");
      let formData = new FormData();
      formData.append("group_id", groupId);
      formData.append("main_value_id", mainValId);
      formData.append("second_value_id", secondValId);
      axios
          .post(`/directory/delete-group-value`, formData)
          .then(resp => {
            if(resp.data.result == 'ok'){
              this.getAll();
            }
            this.$store.dispatch("stopPreloader");
          })
          .catch(err => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
            this.$store.dispatch("stopPreloader");
          });
    },
    addDict(){
      this.$store.dispatch("startPreloader");
      let formData = new FormData();
      formData.append("code", this.newDict.value);
      formData.append("name", this.newDict.description);
      axios
          .post(`/directory/add-dict`, formData)
          .then(resp => {
            if(resp.data.result == 'ok'){
              this.newDict.value = '';
              this.newDict.description = '';
              this.getAll();
            }
            this.$store.dispatch("stopPreloader");
          })
          .catch(err => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
            this.$store.dispatch("stopPreloader");
          });
    },
    deleteDict(id){
      this.$store.dispatch("startPreloader");
      let formData = new FormData();
      formData.append("id", id);
      axios
          .post(`/directory/delete-dict`, formData)
          .then(resp => {
            if(resp.data.result == 'ok'){
              this.getAll();
            }
            this.$store.dispatch("stopPreloader");
          })
          .catch(err => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
            this.$store.dispatch("stopPreloader");
          });
    },
    createValue(dictId){
      this.$store.dispatch("startPreloader");
      let formData = new FormData();
      this.editDictId = dictId;
      formData.append("dict_id", dictId);
      formData.append("value", this.newDictVal.value);
      formData.append("description", this.newDictVal.description);
      axios
          .post(`/directory/add-value`, formData)
          .then(resp => {
            if(resp.data.result == 'ok'){
              this.newDictVal.value = '';
              this.newDictVal.description = '';
              this.getAll();
            }
            this.$store.dispatch("stopPreloader");
          })
          .catch(err => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
            this.$store.dispatch("stopPreloader");
          });
    },
    deleteValue(id){
      this.$store.dispatch("startPreloader");
      let formData = new FormData();
      this.editDictId = this.getDictIdByValId(id);
      formData.append("id", id);
      axios
          .post(`/directory/remove-value`, formData)
          .then(resp => {
            if(resp.data.result == 'ok'){
              this.getAll();
            }
            this.$store.dispatch("stopPreloader");
          })
          .catch(err => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
            this.$store.dispatch("stopPreloader");
          });
    },
    updateValue(id, value, description){
      this.$store.dispatch("startPreloader");
      let formData = new FormData();
      this.editDictId = this.getDictIdByValId(id);
      formData.append("id", id);
      formData.append("value", value);
      formData.append("description", description);
      axios
          .post(`/directory/update-value`, formData)
          .then(resp => {
            if(resp.data.result == 'ok'){
              this.getAll();
            }
            this.$store.dispatch("stopPreloader");
          })
          .catch(err => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
            this.$store.dispatch("stopPreloader");
          });
    },
    getDictIdByValId(valId){
      for(let key in this.dicts){
        for(let val in this.dicts[key].directoryValues){
          if(+this.dicts[key].directoryValues[val].id === +valId){
            return this.dicts[key].id;
          }
        }
      }
      return null;
    },
    getAll(){
      this.$store.dispatch("startPreloader");
      axios
          .post(`/directory/get-all`)
          .then(resp => {
            this.dicts = this.filteredDictsData(resp.data.dicts);
            this.groupDicts = this.filteredGroupsData(resp.data.groups);
            this.editDictId = null;
            this.$store.dispatch("stopPreloader");
          })
          .catch(err => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
            this.$store.dispatch("stopPreloader");
          });
    },
    slideValues(id){
      this.dicts.map(item => {
        if(+item.id === +id){
          item.show_values = !item.show_values;
        }
        return item;
      });
    },
    filteredGroupsData(data){
      let result = data;
      for(let key in result){
        result[key].show_dir_group = false;
        for(let gVal in result[key].directoryGroups){
          result[key].directoryGroups[gVal].show_group_val = false;
        }
      }
      return result;
    },
    filteredDictsData(data){
      let result = data;
      for(let key in result){
        if(+this.editDictId === +result[key].id){
          result[key].show_values = true;
        }else{
          result[key].show_values = false;
        }
      }
      return result;
    }
  },
  created() {
    this.getAll();
  }
};
</script>

<style scoped lang="less">
.dicts--container{
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  flex-direction: row;

  &>div{
    width: 48%;
  }
}
.simple-dicts--container{
  .item{
    padding: 7px 12px;
    box-sizing: border-box;
    margin-top: 12px;
    background-color: #F0F4F9;

    .name-flex{
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      flex-direction: row;

      .name{
        font-size: 16px;
        font-weight: 700;

        .description{
          color: gray;
        }
      }
    }

    .values{
      margin-top: 10px;
    }

    .val-flex{
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      flex-direction: row;

      &>div{
        width: 49%;
      }
    }
  }
}
.group-dicts--container{
  .group-item{
    padding: 7px 12px;
    box-sizing: border-box;
    margin-top: 12px;
    background-color: #F0F4F9;

    .group-name{
      font-size: 16px;
      font-weight: 700;
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-around;
      flex-direction: row;

      &>div:nth-of-type(1){
        width: 80%;
      }
      &>div:nth-of-type(2){
        width: 20%;
      }

      .description{
        color: gray;
      }
    }

    .group-second{
      margin-top: 10px;
      background-color: #7FA1CA;
      padding: 5px;
      color: #fff;

      .group-name--second{
        font-size: 16px;
        font-weight: 700;
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-around;
        flex-direction: row;

        &>div:nth-of-type(1){
          width: 68%;
        }
        &>div:nth-of-type(2){
          width: 9%;
        }
        &>div:nth-of-type(3){
          width: 19%;
        }
      }
      .group-values{
        margin-top: 7px;

        .group-val-item{
          margin-top: 5px;
          display: flex;
          flex-wrap: nowrap;
          justify-content: space-around;
          flex-direction: row;

          &>div:nth-of-type(1){
            width: 40%;
          }
          &>div:nth-of-type(2){
            width: 5%;
          }
          &>div:nth-of-type(3){
            width: 40%;
          }
          &>div:nth-of-type(4){
            width: 10%;
          }
        }
      }
    }
  }
}
.group-values{
  .group-values--main{
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-around;
    flex-direction: row;

    &>div{
      width: 49%;
    }
  }
}
@media (max-width: 700px) {
  .dicts--container{
    flex-wrap: wrap;

    &>div{
      width: 100%;
    }
    &>div:nth-of-type(2){
      margin-top: 40px;
    }
  }
}
</style>
