<template>
  <div
    id="main-template-container"
    v-bind:class="{ 'main-template-min-sidebar': minSidebar }"
  >
    <div class="main-template--top-sidebar">
      <div class="main-template--top-sidebar--title">
        <h4>
          <router-link tag="a" :to="`/`"><b>Enroller</b></router-link>
        </h4>
      </div>
      <div class="main-template--top-sidebar--humburger">
        <f-awesome
          @click="toggleSidebar"
          icon="bars"
          id="main-template-humburger"
        ></f-awesome>
        <!--            <i id="main-template-humburger" class="fas fa-bars"></i>-->
      </div>
      <div class="main-template--top-sidebar--null">

      </div>
      <div class="main-template--top-sidebar--account no-select">
        <div
          @click="toggleAccountBar"
          class="noselect main-template--top-sidebar-email"
        >
          {{ userEmail }}
        </div>
        <f-awesome icon="angle-down"></f-awesome>
        <div
          v-if="showAccountBar"
          class="main-template--top-sidebar--account-more"
        >
          <div class="cursor-pointer" @click="logout">Выйти</div>
        </div>
      </div>
    </div>

    <div class="main-template--left-sidebar no-select">
      <ul>
        <li>
          <router-link tag="a" :to="`/server/`">
            <div>
              <f-awesome icon="server"></f-awesome>
            </div>
            <div>
              Сервер
            </div>
          </router-link>
        </li>
        <li>
          <router-link tag="a" :to="`/dicts/`">
            <div>
              <f-awesome icon="book-open"></f-awesome>
            </div>
            <div>
              Справочники
            </div>
          </router-link>
        </li>
        <li>
          <router-link tag="a" :to="`/roles/`">
            <div>
              <f-awesome icon="person-booth"></f-awesome>
            </div>
            <div>
              Роли и права
            </div>
          </router-link>
        </li>
        <li>
          <router-link tag="a" :to="`/users/`">
            <div>
              <f-awesome icon="users"></f-awesome>
            </div>
            <div>
              Пользователи
            </div>
          </router-link>
        </li>
        <li>
          <router-link tag="a" :to="`/support/`">
            <div>
              <f-awesome icon="comment-alt"></f-awesome>
            </div>
            <div>
              Поддержка
            </div>
          </router-link>
        </li>
      </ul>
    </div>

    <div v-if="showContent" class="main-template-content">
      <div class="main-template--page-title">
        <div>
          <div><slot name="page-title"></slot></div>
        </div>
        <div>
          <div><slot name="page-info"></slot></div>
        </div>
      </div>
      <slot name="page-content"></slot>
    </div>

    <notificator></notificator>
    <preloader></preloader>
    <set-manifest></set-manifest>

  </div>
</template>

<script>
import Notificator from "../Notificator";
import Preloader from "../Preloader";
import SetManifest from "../SetManifest.vue";


export default {
  name: "PanelMainTemplate",
  components: { Preloader, Notificator, SetManifest },
  data() {
    return {
      minSidebar: false,
      clientWidth: false,
      showContent: true,
      showAccountBar: false,
      userEmail: false,
    };
  },
  methods: {
    toggleSidebar() {
      this.minSidebar = !this.minSidebar;
      if (this.minSidebar === false && this.clientWidth < 1000) {
        this.showContent = false;
      } else {
        this.showContent = true;
      }
    },
    toggleAccountBar() {
      this.showAccountBar = !this.showAccountBar;
    },
    logout() {
      this.$store.dispatch("logout").then(() => {
        console.log("logout");
        this.$router.push("/");
      });
    },
  },
  created() {
    this.clientWidth = document.documentElement.clientWidth;
    if (this.clientWidth < 1000) {
      this.minSidebar = true;
    } else {
      this.minSidebar = false;
    }
    let userData = JSON.parse(localStorage.getItem("userData"));
    this.userEmail = userData[0].email;
  }
};
</script>

<style scoped lang="less">
.main-template--page-title {
  display: inline-block;
  padding: 0 10px;
  box-sizing: border-box;
  margin-bottom: 35px;

  & > div:nth-of-type(1) {
    display: inline-block;
    color: #4f4f4f;
    font-weight: 400;
    font-size: 30px;
  }
  & > div:nth-of-type(2) {
    margin-top: 3px;
    font-size: 12px;
    color: gray;
  }
}
.main-template--top-sidebar {
  width: 100%;
  height: 40px;
  position: fixed;
  top: 0;
  background-color: #2b333c;
  color: #fff;
  line-height: 40px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  flex-direction: row;
  z-index: 1;

  .main-template--top-sidebar--humburger {
    width: 5%;
    font-size: 20px;
    text-align: center;

    & > svg {
      cursor: pointer;
      transition: all 0.2s;
    }
    & > svg:hover {
      transform: scale(1.1);
    }
  }
  .main-template--top-sidebar--title {
    padding: 0 15px;

    & > h4 {
      line-height: 40px;
      font-size: 1.5rem;
    }
  }
  .main-template--top-sidebar--null {
    width: 80%;
  }
  .main-template--top-sidebar--account {
    width: 25%;
    text-align: right;
    padding-right: 20px;
    color: #f97979;
    cursor: pointer;
    position: relative;
    line-height: 40px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-around;
    flex-direction: row;

    .main-template--top-sidebar-email {
      width: 90%;
      text-overflow: ellipsis;
      overflow: hidden;
      display: inline-block;
    }

    svg {
      height: auto;
      line-height: 40px;
      display: inline-block;
    }

    .main-template--top-sidebar--account-more {
      position: absolute;
      background-color: #2b333c;
      bottom: -40px;
      right: 0;
      width: 100px;
      text-align: center;
    }
  }
}
.main-template--top-sidebar--notifications {
  display: none;
  position: relative;

  .main-template--top-sidebar--notifications-count {
    position: absolute;
    top: -2px;
    right: -5px;
    color: #fff;
    font-weight: 700;
  }
}
.main-template--top-sidebar--notifications-list {
  width: 200px;
  max-height: 250px;
  overflow: auto;
  position: absolute;
  top: 35px;
  left: 0;
  padding: 18px;
  background-color: #fff;
  color: #333;
  -webkit-box-shadow: 0px 5px 89px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 5px 89px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 5px 89px 0px rgba(0, 0, 0, 0.75);
}
.main-template--top-sidebar--not-item {
  width: 100%;
  margin-top: 12px;
  border-bottom: 0.55px solid #f97979;

  & > div:nth-of-type(1) {
    font-size: 13px;
    /*font-weight: 700;*/
    color: #2b333c;
  }
  & > div:nth-of-type(2) {
    font-size: 11px;
  }
}

.main-template-min-sidebar {
  .main-template-content {
    position: relative;
    margin-left: 0;
  }
  .main-template--left-sidebar {
    display: none;
  }
}
.main-template-content {
  padding-bottom: 35px;
  padding-left: 5px;
  padding-right: 5px;
  box-sizing: border-box;
  margin-top: 45px;
  margin-left: 220px;
}
.main-template--left-sidebar {
  position: fixed;
  top: 40px;
  left: 0;
  bottom: 0;
  width: 210px;
  background-color: #182b49;
  padding: 35px 0 35px 0;
  /*text-align: center;*/
  overflow: auto;
  -webkit-box-shadow: 2px 1px 12px 0px rgba(50, 50, 50, 0.75);
  -moz-box-shadow: 2px 1px 12px 0px rgba(50, 50, 50, 0.75);
  box-shadow: 2px 1px 12px 0px rgba(50, 50, 50, 0.75);

  & > ul {
    list-style-type: none;
    padding: 0;
    margin: 0;

    li {
      margin-top: 4px;
      padding-top: 8px;

      a {
        font-size: 14px;
        color: #ffffff;
        display: inline-block;
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-around;
        flex-direction: row;
        padding: 12px 0;
        box-sizing: border-box;

        & > div:nth-of-type(1) {
          width: 25%;
          font-size: 16px;
          text-align: center;
        }
        & > div:nth-of-type(2) {
          width: 75%;
          font-size: 16px;
        }
      }
      a:hover svg {
        transform: scale(1.1);
      }
    }
    a.router-link-active {
      /*color: #F97979;*/
      background-color: #263d62;
    }
  }
}
@media (max-width: 700px) {
  .main-template--top-sidebar {

    .main-template--top-sidebar--humburger {
      width: 25%;
    }
    .main-template--top-sidebar--title {
      & > h4 {
        font-size: 1.2rem;
      }
    }
    .main-template--top-sidebar--null {
      width: 70%;
    }
  }
}
</style>
